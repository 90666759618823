import { useState, useEffect } from "react";
import "./CareStatus.scss";
import SuccessIcon from "../../../assets/images/icons/successsnack.svg";
import FailureIcon from "../../../assets/images/icons/failedsnack.svg";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import advService from "../../../util/advService";
import service from "../../../util/axiosConfig";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../../types";
import {
    Box,
    Button,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../../assets/images/addons/Mobile.svg";
import Iconify from "../../../components/iconify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const illustrationSuper =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/Super_TopUp/superIllustration.png";
const PendingIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/assets/images/icons/Pending.png";

const PaymentStatusText = {
    Loading: {
        icon: (
            <Iconify
                icon="eos-icons:bubble-loading"
                sx={{ height: "80px", width: "80px" }}
            />
        ),
        title: "Loading...",
        subText: "Please wait",
    },
    Pending: {
        icon: (
            <Iconify
                icon="mdi:receipt-text-pending"
                sx={{ height: "80px", width: "80px" }}
            />
        ),
        title: "Payment pending",
        subText: "Please wait for sometime. We'll get back to you",
    },
    Success: {
        icon: (
            <Iconify
                icon="material-symbols:check-circle-rounded"
                sx={{ height: "80px", width: "80px", color: "#05C168" }}
            />
        ),
        title: "Payment successful",
        subText:
            "Congratulations, your policy {policyNumber} has been issued. The policy document will be sent to your email within 24hrs.",
    },
    Failed: {
        icon: (
            <Iconify
                icon="material-symbols:cancel-rounded"
                sx={{ height: "80px", width: "80px", color: "#FF5A65" }}
            />
        ),
        title: "Payment unsuccessful",
        subText:
            "We are validating your payment for proposal number {proposalNumber} if your payment has been deducted or else you can retry again.",
    },
};

const CareStatus = () => {
    const [status, setStatus] = useState(PaymentStatusText.Loading);
    const [policyNumber, setPolicyNumber] = useState("");
    const [timer, setTimer] = useState(10);
    const [showTimer, setShowTimer] = useState(false);
    const { proposalNumber, payStatus } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        if (proposalNumber) {
            // service
            //     .post(
            //         `https://app.imt.care/api/node/care/paymentComplete/${proposalNumber}`,
            //         {}
            //     )
            //     .then((res) => {
            //         // if (res.data.message === "Success") {
            //         if (res.data.response.success === 1) {
            //             setStatus(PaymentStatusText.Success);
            //             setPolicyNumber(res.data.response.policyNum);

            //         } else {
            //             setStatus(PaymentStatusText.Failed);
            //             const intervalId = setInterval(() => {
            //                 if (timer > 0) {
            //                     setTimer((prevTimer) => prevTimer - 1);
            //                 }
            //             }, 1000);
            //             setShowTimer(true);
            //         }
            // setTimeout(() => {
            //     (window as any)?.ReactNativeWebView?.postMessage(
            //         JSON.stringify({
            //             tag: "GoBack",
            //             user: null,
            //             token: null,
            //         })
            //     );
            //     navigate("/home");
            // }, 10000);
            //     })
            //     .catch((err) => {
            //         setStatus(PaymentStatusText.Failed);
            //         setTimeout(() => {
            //             (window as any)?.ReactNativeWebView?.postMessage(
            //                 JSON.stringify({
            //                     tag: "GoBack",
            //                     user: null,
            //                     token: null,
            //                 })
            //             );
            //             navigate("/home");
            //         }, 10000);
            //     });
            const intervalId = setInterval(() => {
                if (timer > 0) {
                    setTimer((prevTimer) => prevTimer - 1);
                }
            }, 1000);
            setShowTimer(true);
            if (payStatus === "true") {
                setStatus(PaymentStatusText.Success);
                setPolicyNumber(proposalNumber);
                setTimeout(() => {
                    (window as any)?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                            tag: "GoBack",
                            user: null,
                            token: null,
                        })
                    );
                    navigate("/home");
                }, 10000);
            } else {
                setStatus(PaymentStatusText.Failed);
                setPolicyNumber(proposalNumber);
                setTimeout(() => {
                    (window as any)?.ReactNativeWebView?.postMessage(
                        JSON.stringify({
                            tag: "GoBack",
                            user: null,
                            token: null,
                        })
                    );
                    navigate("/home");
                }, 10000);
            }
        }
    }, [proposalNumber]);

    return (
        <Box
            sx={{
                // height: "450px",

                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                // backgroundPosition: "center",
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                // position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: "black",
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => null}
                    /> */}
                    <KeyboardBackspaceIcon
                        sx={{
                            color: "black",
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => null}
                    />
                    <Stack direction="column" gap="4px">
                        <Typography
                            variant="h4"
                            sx={{
                                color: theme.palette.grey[800],
                                textAlign: "center",
                            }}
                        ></Typography>
                    </Stack>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: isMobile ? "24px 20px" : "40px 32px",
                    gap: "32px",
                    alignSelf: "stretch",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    borderRadius: { sm: "48px", xs: "24px 24px 0 0" },

                    border: isMobile
                        ? "0px solid var(--Components-Outline, #ECEFFF)"
                        : "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    minHeight: { xs: "100%", sm: "456px" },
                    alignItems: "center",
                    margin: "auto",
                }}
            >
                {status.icon}
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: "center",
                        color: theme.palette.text.dark,
                    }}
                >
                    {status.title}
                </Typography>
                <Divider
                    variant="dashed"
                    sx={{
                        width: "100%",
                        borderColor: "grey.400",
                        opacity: 0.3,
                    }}
                />
                {status === PaymentStatusText.Pending ? (
                    <Typography
                        variant="body2"
                        sx={{
                            color: theme.palette.text.dark,
                            textAlign: "center",
                        }}
                    >
                        {status.subText}
                    </Typography>
                ) : null}
                {status === PaymentStatusText.Success && policyNumber ? (
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: status.subText.replace(
                                "{policyNumber}",
                                `<strong>${policyNumber}</strong>`
                            ),
                        }}
                        variant="body2"
                        sx={{
                            color: theme.palette.text.dark,
                            textAlign: "center",
                        }}
                    />
                ) : null}
                {status === PaymentStatusText.Failed && proposalNumber ? (
                    <Typography
                        dangerouslySetInnerHTML={{
                            __html: status.subText.replace(
                                "{proposalNumber}",
                                `<strong>${policyNumber}</strong>`
                            ),
                        }}
                        sx={{
                            color: theme.palette.text.dark,
                            textAlign: "center",
                        }}
                        variant="body2"
                    />
                ) : null}
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: "32px",
                        alignItems: "center",
                        position: isMobile ? "absolute" : "relative",
                        bottom: "0",
                        left: "0",

                        padding: isMobile ? "24px 24px" : "0px 0px",
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "var(--primary)",
                            height: "42px",
                            width: isMobile ? "100%" : "335px",
                        }}
                        type="submit"
                        size="small"
                        onClick={() => {
                            navigate("/home");
                        }}
                    >
                        Got it
                    </Button>
                    {showTimer && (
                        <Typography
                            variant="body3"
                            color={theme.palette.text.secondary}
                            sx={{ textAlign: "center" }}
                        >
                            You will be redirected to homepage in {timer}{" "}
                            seconds.
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );

    return (
        <div className="carepaymentstatuspage">
            <div className="carepaymentstatuspage_banner">
                <img src={illustrationSuper} alt="" className="ml-0" />
            </div>
            <div className="carepaymentstatuspage_body mt30">
                {/* <img
                    src={status.icon}
                    alt="status icon"
                    width={60}
                    height={60}
                /> */}
                <div className="carepaymentstatuspage_text mt10">
                    <Typography
                        variant="subtitle1"
                        className="headings_font_color"
                    >
                        {status.title}
                    </Typography>
                    {status === PaymentStatusText.Pending ? (
                        <Typography variant="body3" className="fontColor_dark">
                            {status.subText}
                        </Typography>
                    ) : null}
                    {status === PaymentStatusText.Success && policyNumber ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: status.subText.replace(
                                    "{policyNumber}",
                                    `<strong>${policyNumber}</strong>`
                                ),
                            }}
                        ></p>
                    ) : null}
                    {status === PaymentStatusText.Failed && proposalNumber ? (
                        <p
                            dangerouslySetInnerHTML={{
                                __html: status.subText.replace(
                                    "{proposalNumber}",
                                    `<strong>${policyNumber}</strong>`
                                ),
                            }}
                        ></p>
                    ) : null}
                    {showTimer && (
                        <Typography
                            variant="body3"
                            className="headings_font_color mt5"
                        >
                            You will be redirected to homepage in {timer}{" "}
                            seconds.
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CareStatus;
