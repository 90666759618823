import { Box, Chip, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface props {
    dateAlter?: {
        daysRemaining: number;
        color: "primary" | "secondary" | "error" | "success";
    };
    insuranceCategory?: string;
    heading: string;
    endDate?: string;
    bgImage: string;
    categoryLogo: string;
    covers?: string;
    onClick: () => void;
}

const NewPolicyCard = ({
    dateAlter,
    insuranceCategory,
    heading,
    endDate,
    bgImage,
    categoryLogo,
    onClick,
    covers,
}: props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: { sm: "349px", xs: "100%" },
                borderRadius: "24px",
                cursor: "pointer",
                height: { sm: "232px", xs: "fit-content" },
                border: `1px solid ${theme.palette.grey[300]}`,
                overflow: "hidden",
            }}
            // className="surface2"
            onClick={onClick}
        >
            <div style={{ position: "relative" }}>
                <img
                    src={bgImage}
                    alt="bgImage"
                    height={"56px"}
                    width={"100%"}
                    style={{
                        objectFit: "cover",
                        borderTopLeftRadius: "24px",
                        borderTopRightRadius: "24px",
                    }}
                />
                {dateAlter && (
                    <Chip
                        size="small"
                        label={
                            <Typography variant="label" color="#FFFFFF">
                                {`Expired in ${dateAlter.daysRemaining} days`}
                            </Typography>
                        }
                        color={dateAlter.color}
                        sx={{
                            position: "absolute",
                            right: "4.58%",
                            top: "30%",
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: "flex",
                        width: "60px",
                        height: "60px",
                        padding: "0px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: "500px",
                        border: "4px solid #FFF",
                        background: "#F4F7FF",
                        position: "absolute",
                        bottom: "-30px",
                        left: "13%",
                        transform: "translateX(-50%)",
                    }}
                >
                    <img
                        src={categoryLogo}
                        alt="logo"
                        width={"60px"}
                        height={"60px"}
                        style={{ objectFit: "cover", flexShrink: 0 }}
                    />
                </Box>
            </div>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    paddingTop: "38px",
                    gap: "8px",
                    alignSelf: "stretch",
                    background: "#FFF",
                    borderBottomLeftRadius: "24px",
                    borderBottomRightRadius: "24px",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        minHeight: { sm: 60, xs: "fit-content" },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                    }}
                    color={theme.palette.text.primary}
                >
                    {heading}
                </Typography>
                {endDate && (
                    <Typography
                        variant="body3"
                        color={theme.palette.text.primary}
                    >
                        Valid till: {endDate}
                    </Typography>
                )}
                <Divider
                    variant="dashed"
                    sx={{
                        borderColor: "grey.400",
                        opacity: 0.3,
                    }}
                />
                {covers && (
                    <Typography
                        variant="overline"
                        color={theme.palette.text.secondary} //theme.palette.text.secondary
                        sx={{
                            textTransform: "none",
                            overflow: "ellipsis",
                        }}
                    >
                        <Box display="flex" gap="16px" alignItems="center">
                            {covers?.split("*").map((cover, index, array) => (
                                <React.Fragment key={index}>
                                    <span>{cover}</span>
                                    {index < array.length - 1 && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="6"
                                            height="6"
                                            viewBox="0 0 6 6"
                                            fill="none"
                                        >
                                            <circle
                                                cx="3"
                                                cy="3"
                                                r="3"
                                                fill="#ECEFFF"
                                            />
                                        </svg>
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default NewPolicyCard;
