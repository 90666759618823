// export let BASE_URL = "https://app.imt.care/";
// change baseurl to host url if process.env.REACT_APP_ENV === "/dashboard" else localhost
export let BASE_URL =
    process.env.REACT_APP_ENV === "/dashboard"
        ? "https://" + window.location.hostname + "/"
        : "http://localhost:8080/";

export const BOT_BACKEND_URL = "https://bot.insuremyteam.com";
export const JWT_SECRET = "rachit@gmail.com";

export const NODE_BE_BASE_URL =
    process.env.REACT_APP_ENV === "/dashboard"
        ? "https://" + window.location.hostname + "/api/node/"
        : "http://localhost:8080/api/node/";

// export const NODE_BE_BASE_URL = "https://app.imt.care/api/node/";
export const GROUPHEALTH = "https://grouphealth.shiftrisk.com";

export const SHARE_PDF = BASE_URL + "v1/member/card/download/v4/{pId}";

export const PROD_FE_LOGIN = "app.imt.care/signIn";
export const AUTHENTICATE = "v1/user/authenticate";
export const SIGN_UP = BASE_URL + "v1/onboard/signup";
export const VERIFY = BASE_URL + "v1/onboard/verify/phone";
export const RESEND = BASE_URL + "v1/onboard/sendSms";
export const VERIFYPHONE = BASE_URL + "v1/user/verifyPhone";
export const EDIT_PHONE = BASE_URL + "v1/onboard/phone";
export const GET_LEADS = BASE_URL + "v1/onboard/leads";
export const ADD_NEW_LEAD = BASE_URL + "v1/onboard/lead";
export const ADDITIONAL_INFO = BASE_URL + "v1/onboard/additionalData";
export const FORGOT_PASSWORD_EMAIL_LINK =
    BASE_URL + "v1/onboard/password/forget";
export const PASSWORD_VERIFY_LINK = BASE_URL + "v1/onboard/validate/link";
export const RESET_PASSWORD = BASE_URL + "v1/onboard/password/reset";
// export const RESEND = BASE_URL + "v1/onboard/sendSms";
export const VERIFY_MULTIPLE_PHONE_USERS =
    BASE_URL + "v1/onboard/reset/sendSms";
export const VERIFY_MULTIPLE_PHONE_OTP =
    BASE_URL + "v1/onboard/reset/verifyOtp";
export const ESTIMATE = BASE_URL + "v1/estimate";
// export const ESTIMATE_PLAN = BASE_URL + "v1/estimate/plan";
export const REUPLOAD_MEMBER_DATA = BASE_URL + "v1/estimate/member/reupload/";
export const ESTIMATE_INSURANCE = BASE_URL + "v1/estimate/insurer";
export const ESTIMATE_INSURANCE_SELECT =
    BASE_URL + "v1/estimate/insurer/select";
export const LEAD_ESTIMATE = ESTIMATE + "/lead";
export const POLICY_INITIATE = BASE_URL + "v1/onboard/company/initiate";
export const GSTIN = BASE_URL + "v1/onboard/company/gstIn";
export const COMPANY_GST_SAVE = BASE_URL + "v1/onboard/company/details";
export const FILE_UPLOAD = BASE_URL + "v1/policy/upload/v2";
export const FILE_DOWNLOAD = BASE_URL + "v1/policy/download/v2";
export const PAY = BASE_URL + "test/payment";
export const INVOICE_INIT = BASE_URL + "v1/onboard/company/gPolicy/invoice/";
export const MEMBERS = BASE_URL + "v1/members";
export const MEMBER_UPDATE = BASE_URL + "v1/member/";
export const TPA_UPDATE = BASE_URL + "v1/member/tpaId/v2/";
export const MEMBER_HOME = BASE_URL + "v1/home/members/v2/";
export const ENROLLMENT = BASE_URL + "v1/enrollment/home";
export const ADD_ENROLLMENT = BASE_URL + "v1/enrollment/member";
export const CONFIRM_ENROLLMENT = BASE_URL + "v1/enrollment/confirm";
export const DELETE_ENROLLMENT = BASE_URL + "v1/enrollment/member/";
export const ADD_MEMBER = BASE_URL + "v1/member";
export const DELETE_MEMBER = BASE_URL + "v1/member/delete";
export const MEMBERS_PAGE = BASE_URL + "v1/page/members/v2";
export const MEMBERS_SEARCH = BASE_URL + "v1/member/search/v2";
export const MEMBERS_DOWNLOAD = BASE_URL + "v1/member/list/download/v2";
export const MEMBER_DETAILS = BASE_URL + "v1/member/detail";
export const MEMBER_DELETE = BASE_URL + "v1/member/dependent";
export const ADD_PAYMENT = BASE_URL + "v1/payment";
export const CHANGE_PAYMENT_AMOUNT = BASE_URL + "v1/payment/admin";
export const INTIALISE = BASE_URL + "v1/user/initiate";
export const LOGIN = BASE_URL + "v1/user/login";
export const CARD_DOWNLOAD = BASE_URL + "v1/member/download/";
export const CARD_POST_DOWNLOAD = BASE_URL + "v1/member/card/download/v3/{pid}";
export const CARD_EMAIL = BASE_URL + "v1/member/email/";
export const MEMBER_PREPAY = BASE_URL + "v1/member/prepay/";
export const POLICY_DOWNLOAD = BASE_URL + "v1/policy/download/GMC/";
export const HOSPITAL = BASE_URL + "v1/hospital/";
export const GET_LEAD_INFO = BASE_URL + "v1/lead/";
export const ENDORSEMENT_DATA = BASE_URL + "v1/policy/endorsement";
export const ACTIVATE_POLICY = BASE_URL + "v1/policy/activate/";
export const LEAD_BASIC_DETAILS = BASE_URL + "/v1/leads/basic/";
export const ADMIN_ACTIONS = NODE_BE_BASE_URL + "insurer/excelDownload";
export const GET_ALL_HEALTH_PACKAGES = NODE_BE_BASE_URL + "health/package";
export const GET_TESTS = NODE_BE_BASE_URL + "health/package/details";
export const ADMIN_ACTIONS_GET = BASE_URL + "v1/admin/tasks/v2/";
export const FEATURE = BASE_URL + "v1/policy/admin/features/v2";
export const GET_ADMIN_USER_LIST = BASE_URL + "v1/user/admin/list/";
export const DELETE_ADMIN_USER = BASE_URL + "v1/user/admin/";
export const CREATE_ADMIN_USER = BASE_URL + "v1/user/admin/create/";
export const EDIT_ADMIN_USER = BASE_URL + "v1/user/admin/edit/";
export const PAYMENT = BASE_URL + "v1/payment";
export const BATCH_OLD = BASE_URL + "v1/admin/batch/";
export const BATCH = BASE_URL + "v1/admin/batch/v2/";
export const EDIT_ENDORSEMENT = BASE_URL + "v1/admin/batch/update/v2/";
export const HOME = BASE_URL + "v1/employer/home/";
export const HOME_EMPLOYEE = BASE_URL + "v1/employee/home/";
export const NEW_HOME_EMPLOYEE = BASE_URL + "v1/employee/home/v2/";
export const HOME_BROKER_AGENT_CHECK =
    BASE_URL + "v1/user/admin/employee/check/";
export const MFINE = "https://qa.api.mfine.co/api/v1/silent-login";
export const MFINE_CHECK = BASE_URL + "v1/employee/mfine/doctor";

export const MEDICAL_TEST_URL = BASE_URL + "v1/employee/medicalTest";
export const PHARMACY_URL = BASE_URL + "v1/employee/pharmacy";
export const DOCTOR_URL = BASE_URL + "v1/employee/doctor";

export const POLICY_LEADS = BASE_URL + "v1/lead/extra";
export const LEAD_COMMENT_GET = BASE_URL + "v1/lead/comments";
export const LEAD_COMMENT_POST = BASE_URL + "v1/lead/comment";

export const DOWNLOAD_TEMPLATE = BASE_URL + "download/MEMBER_UPLOAD/";
export const UPLOAD_CSV = BASE_URL + "v1/member/new/";
export const DOWNLOAD_TEMPLATE_FILE = NODE_BE_BASE_URL + "template/addMember";

export const DOCTOR_INTEREST = BASE_URL + "v1/plan/dr/";
export const HEALTH_DIAGNOSTIC = BASE_URL + "v1/plan/health/";

export const SEND_INVITE = BASE_URL + "v1/member/invite/";
export const SEND_INVITE_USER = BASE_URL + "v1/user/admin/invite/";

export const AGENTS_LIST = BASE_URL + "v1/admin/agents";
export const AGENT = BASE_URL + "v1/admin/agent";
export const AGENTUSERS = BASE_URL + "v1/agent";
export const AGENTUSERS_LIST = BASE_URL + "v1/agents";
export const BROKERS = BASE_URL + "v1/admin/broker";

export const MAGIC_LOGIN = BASE_URL + "v1/user/magicLogin";

export const REFERENCE_NAME = BASE_URL + "v1/admin/agent/name/map";
// export const SETTLEMENT_DETAILS = BASE_URL + "v1/policy/admin/settlement/v2";

export const GTI = BASE_URL + "v1/member/policy/check/";
export const GET_MEMBERS = BASE_URL + "v1/home/members/v2";

// export const POLICY_UPDATE = BASE_URL + "v1/policy/admin/policy/update/v2/";

export const INSURANCE = BASE_URL + "v1/admin/insuranceCompany";

export const EDIT_ESTIMATE = BASE_URL + "v1/lead/estimate/";
// export const BANK = BASE_URL + "v1/policy/admin/bank/";

export const PHONE_CHECK = BASE_URL + "v1/employee/phone/check";

export const EMPLOYEE_EDIT = BASE_URL + "v1/employee";
export const EMPLOYEE_UPLOAD = BASE_URL + "v1/employee/upload";
export const EMPLOYEE_DOWNLOAD = BASE_URL + "v1/employee/download";

export const ADMIN_DOWNLOAD = BASE_URL + "v1/admin/download/id";
export const changeBaseUrl = (val) => {
    BASE_URL = val;
};

export const INSURER_TPAS = BASE_URL + "v1/master/insurerTpas";
export const CLAIM_UPLOAD = BASE_URL + "v1/admin/upload";
export const CLAIM_DOWNLOAD = BASE_URL + "v1/download/generic";
export const CLAIM_DETAILS = NODE_BE_BASE_URL + "company/claim/";
export const CLAIM_CUSTOMERCARE = BASE_URL + "v1/claim/contact/";
export const CLAIM_OPERATIONTEAM = BASE_URL + "v1/employer/home/";
// export const CLAIMS_TABLE = BASE_URL + "v1/claims/v2/";
export const GET_ALL_EMPLOYEE_CLAIM = BASE_URL + "v1/employee/claims";
export const GET_EMPLOYEE_CLAIM = BASE_URL + "v1/employee/claim";
export const UPLOAD_INTIMATE_DOCUMENT =
    BASE_URL + "v1/claim/admission/document/";
export const UPLOAD_INTERACTION_DOCUMENT =
    BASE_URL + "v1/employee/claim/document/interaction/";
export const CLAIM_COMMENT = BASE_URL + "v1/employee/claim/interaction/";
export const ADMIN_CLAIM_COMMENT = BASE_URL + "v1/admin/claim/interaction/";
export const ADMIM_UPLOAD_INTERACTION_DOCUMENT =
    BASE_URL + "v1/admin/claim/document/interaction/";
export const ADMIN_CLAIM_FILE_SELECTION_LIST =
    BASE_URL + "v1/admin/claim/admission/document/list/";

export const GET_CLAIM_INTIMATE =
    BASE_URL + "v1/claim/admission/document/list/";
export const GET_CLAIM_INTERACTION = BASE_URL + "v1/claim/interaction/";
export const GET_CLAIM_ADMISSION_DOCS =
    BASE_URL + "v1/claim/admission/document/";

export const CLAIMS_UPLOAD = BASE_URL + "v1/admin/claim/upload/";

export const AGENT_LOGO_UPLOAD = BASE_URL + "v1/admin/agent/upload";
export const LEAD_LOGO_UPLOAD = BASE_URL + "v1/lead/extra/upload/";
export const CLAIM_INTIMATE_EMPLOYEE = BASE_URL + "v1/employee/claim";
export const CLAIM_INTIMATE_EMPLOYER = BASE_URL + "v1/employer/claim";
export const CLAIM_INTIMATE_ADMIN = BASE_URL + "v1/admin/claim";
export const GET_CLAIM_ADMIN = BASE_URL + "v1/claim";

export const FLOAT_BALANCE_UPDATE = BASE_URL + "v1/policy/admin/floatBalance/";

export const MASTER_POLICY_TABLE = NODE_BE_BASE_URL + "admin/master/plans";
export const ADD_MASTER_POLICY = NODE_BE_BASE_URL + "admin/master/plan";
export const UPDATE_MASTER_POLICY = NODE_BE_BASE_URL + "admin/master/plan/";
export const GET_INSURERS = BASE_URL + "v1/master/insurers";
export const GET_TPAS = BASE_URL + "v1/master/tpas";
export const GET_MASTER_POLICY = NODE_BE_BASE_URL + "admin/master/plans/unique";

export const GET_MASTER_POLICY_DATA =
    NODE_BE_BASE_URL + "admin/master/plan/unique/";

export const GET_TOPUP_CHARGES = NODE_BE_BASE_URL + "topup/charges";
export const MARK_INTEREST_IN_TOPUP = NODE_BE_BASE_URL + "topup/markInterest";

// export const EXPIRE_POLICY = BASE_URL + "v1/policy/admin/expire/v2/";
export const EMPLOYEE_POLICIES = BASE_URL + "v1/policy/employee";
export const POLICY_DETAILS = BASE_URL + "v1/policy/employee/features/GMC";

export const CARE_CREATE_PROPOSAL = NODE_BE_BASE_URL + "care/createPolicy";
export const CARE_GETPDF = NODE_BE_BASE_URL + "care/pdf";
export const CARE_GETSTATUS = NODE_BE_BASE_URL + "care/status";
export const CARE_ADRESS_SEARCH = NODE_BE_BASE_URL + "care/adressSearch";
export const CARE_GETPOLICY = NODE_BE_BASE_URL + "care/getPolicy";

export const GET_PARAMS = BASE_URL + "v1/master/params";
export const GET_PARAMS_RESPONSE = BASE_URL + "v1/admin/params/";
export const UPDATE_PARAMS = BASE_URL + "v1/admin/params/";
export const DELETE_PARAMS = BASE_URL + "v1/admin/params/";
export const UNSUBSCRIBE = BASE_URL + "v1/op/unsubscribe/";
export const SUBSCRIBE = BASE_URL + "v1/op/subscribe/";

export const DOWNLOAD_PDF = NODE_BE_BASE_URL + "generatePdf";
export const DOWNLOAD_REQUEST_LOG =
    BASE_URL + "v1/member/bulk/details/{requestId}";
