import {
    Box,
    useMediaQuery,
    Typography,
    IconButton,
    Button,
    Stack,
    Drawer,
    Divider,
    useTheme,
    CircularProgress,
} from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import React, { useEffect, useState } from "react";
import { Close, InfoOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import celebrationAnimation from "./Animation - 1721635282789.json";
import copyFunction from "../../util/function/copyFunction";
import Lottie from "lottie-react";
import Iconify from "../../components/iconify";
import { useQuery } from "react-query";
import service from "../../util/axiosConfig";

import { homeService } from "../../services/api/homeService";
import { POST_COUPON_REVEAL, REEDEEM_COUPON } from "../../util/newconfig";
import advService from "../../util/advService";
import { AxiosResponse } from "axios";
import { JavaResponse } from "../../types";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { LoadingButton } from "@mui/lab";
import openIphoneLink from "../../util/function/openIphoneLink";
const CopyIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/general/copy.svg";

export interface BenefitInfo {
    imageUrl: string;
    active: boolean;
    buttonIntent: any;
    category: string;
    partner: string;
    partnerPlan: string;
    hcwSteps: string;
    couponType: number;
    couponCode: string;
    cardHeading: string;
    cardSubText: string;
}

const BenefitView = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [open, setOpen] = React.useState(false);

    const [revealed, setRevealed] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const [benefitInfo, setBenefitInfo] = useState<BenefitInfo | null>(null);
    const [healthCareOfferSteps, setHealthCareOfferSteps] = useState([]);
    const [healthCareRedeemSteps, setHealthCareRedeemSteps] = useState([]);
    const [healthCareFAQs, setHealthCareFAQs] = useState([]);
    const [healthCareTnC, setHealthCareTnC] = useState([]);
    const [healthOfferDescription, setHealthOfferDescription] = useState("");
    const [vendorDescription, setVendorDescription] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(sessionStorage.getItem("user") ?? "{}");

    const theme = useTheme();

    const { id } = useParams<{ id: string }>();

    const { data, isSuccess, isLoading } = useQuery<BenefitInfo>(
        ["benefit", id],
        () => homeService.getBnefitInfo(id as string),
        {
            staleTime: 0,
            enabled: !!id && user.userType.toLowerCase() === "employee",
            // onSuccess: (data: BenefitInfo) => {
            //     const parsedData = JSON.parse(data.hcwSteps);
            //     const filteredOfferSteps =
            //         parsedData.healthCareOfferSteps.filter(
            //             (step: { step: string }) => step.step !== ""
            //         );
            //     const filteredRedeemSteps =
            //         parsedData.healthCareRedeemSteps.filter(
            //             (condition: { condition: string }) =>
            //                 condition.condition !== ""
            //         );
            //     const filteredFAQs = parsedData.healthCareFAQs.filter(
            //         (faq: { question: string; answer: string }) =>
            //             faq.question !== "" && faq.answer !== ""
            //     );
            //     const filteredTnC = parsedData.healthCareTnC.filter(
            //         (tnc: { terms: string }) => tnc.terms !== ""
            //     );

            //     setHealthCareOfferSteps(filteredOfferSteps);
            //     setHealthCareRedeemSteps(filteredRedeemSteps);
            //     setHealthCareFAQs(filteredFAQs);
            //     setHealthCareTnC(filteredTnC);
            //     setHealthOfferDescription(parsedData.healthOfferDescription);
            //     setVendorDescription(parsedData.vendorDescription);

            //     setBenefitInfo(data);
            // },
        }
    );

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            const parsedData = JSON.parse(data?.hcwSteps ?? "{}");
            const filteredOfferSteps = parsedData?.healthCareOfferSteps?.filter(
                (step: { step: string }) => step.step !== ""
            );
            const filteredRedeemSteps =
                parsedData?.healthCareRedeemSteps?.filter(
                    (condition: { condition: string }) =>
                        condition.condition !== ""
                );
            const filteredFAQs = parsedData?.healthCareFAQs?.filter(
                (faq: { question: string; answer: string }) =>
                    faq.question !== "" && faq.answer !== ""
            );
            const filteredTnC = parsedData?.healthCareTnC?.filter(
                (tnc: { terms: string }) => tnc.terms !== ""
            );

            setHealthCareOfferSteps(filteredOfferSteps);
            setHealthCareRedeemSteps(filteredRedeemSteps);
            setHealthCareFAQs(filteredFAQs);
            setHealthCareTnC(filteredTnC);
            setHealthOfferDescription(parsedData.healthOfferDescription);
            setVendorDescription(parsedData.vendorDescription);

            setBenefitInfo(data);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showAnimation) {
            timer = setTimeout(() => {
                setShowAnimation(false);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [showAnimation]);

    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCouponReveal = () => {
        const config = {
            ...POST_COUPON_REVEAL,
            url: POST_COUPON_REVEAL.url.replace("{templateId}", id as string),
        };

        advService(
            config,
            {},
            (res: AxiosResponse<JavaResponse<{ code: string }>>) => {
                if (res?.data.success) {
                    setCouponCode(res.data.response.code);
                    setRevealed(true);
                    setShowAnimation(true);
                }
            }
        );
    };

    const handleRedeem = () => {
        setLoading(true);
        let config = {
            ...REEDEEM_COUPON,
            url: REEDEEM_COUPON.url.replace("{templateId}", id as string),
        };
        advService(
            config,
            {},
            (
                res: AxiosResponse<
                    JavaResponse<{
                        label: string;
                        value: string;
                    }>
                >
            ) => {
                if (res.data.success) {
                    const { label, value } = res.data.response;
                    if (label === "internal") {
                        if (value) {
                            navigate(`/${value}`);
                        }
                    } else if (label === "external") {
                        if (value) {
                            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
                            if(isIOS){
                                openIphoneLink(value);
                            } else {
                                window.open(value, "_blank");
                            }
                        }
                    } else {
                        console.error("Unknown intent:", label);
                    }
                    setLoading(false);
                }
            }
        );
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
    return (
        <>
            {showAnimation && (
                <Lottie
                    animationData={celebrationAnimation}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                    }}
                />
            )}
            <Box
                sx={{
                    paddingTop: { sm: "33px", xs: "" },
                    background: `url(${
                        isMobile ? benifitMobilebg : benifitDesktopbg
                    })`,
                    backgroundSize: { sm: "contain", xs: "cover" },
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    backgroundPositionX: "center",
                    margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                    gap: {
                        sm: "36px",
                        xs:
                            (benefitInfo?.couponType ?? 0) > 0
                                ? "150px"
                                : "82px",
                    },
                }}
            >
                <Box
                    sx={{
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        padding: { xs: "24px 16px", sm: "0" },
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate(-1)}
                    /> */}
                    <KeyboardBackspaceIcon
                        sx={{
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate(-1)}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        width: { xs: "100%", sm: "770px" },
                        position: "relative",

                        flexDirection: { xs: "column", sm: "row" },

                        padding: { sm: "24px", xs: "20px" },
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        alignContent: "flex-start",
                        gap: { xs: "40px", sm: "24px" },
                        flexWrap: "wrap",
                        borderRadius: { sm: "48px", xs: "24px 24px 0 0" },

                        border: "1px solid var(--Components-Outline, #ECEFFF)",
                        background: "#FFF",
                        boxShadow: {
                            sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                            xs: "",
                        },
                        margin: "auto",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            padding: { sm: "16px", xs: "" },
                            flex: 1,

                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "24px",
                            borderRadius: "32px",
                            background: { xs: "", sm: "#F4F7FF" },
                            minHeight: { xs: "100%", sm: "456px" },
                            marginTop: { xs: "40px", sm: "0" },
                        }}
                    >
                        <Box
                            sx={{
                                height:
                                    (benefitInfo?.couponType ?? 0) > 0
                                        ? "183px"
                                        : "fit-content",
                                width: isMobile ? "calc(100% - 40px)" : "335px",
                                padding: "16px",
                                backgroundColor:
                                    (benefitInfo?.couponType ?? 0) > 0
                                        ? "transparent"
                                        : "white",
                                backgroundImage:
                                    (benefitInfo?.couponType ?? 0) > 0
                                        ? `url('https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Benefits+art/Benefits+card+BG.svg')`
                                        : "",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                position: { xs: "absolute", sm: "static" },
                                top: {
                                    xs: benefitInfo?.couponType
                                        ? "-150px"
                                        : "-80px",
                                    sm: "0",
                                },
                                boxShadow: {
                                    xs: "0 2px 0px rgba(39, 57, 147, 0.08)",
                                    sm: "0px 2px 0px 0px rgba(39, 57, 147, 0.08)",
                                },

                                borderRadius: "24px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "column",
                                // position: "relative",
                                // "&::before, &::after": {
                                //     content: '""',
                                //     position: "absolute",
                                //     top: "calc(50% - 15px)",
                                //     bottom: "calc(50% - 15px)",
                                //     width: "32px",
                                //     height: "32px",
                                //     backgroundColor: "#F4F7FF",
                                //     borderRadius: "50%",
                                // },
                                // "&::before": {
                                //     left: "-15px",
                                // },
                                // "&::after": {
                                //     right: "-15px",
                                // },
                            }}
                        >
                            {/* <img src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Benefits+art/Benefits+card+BG.svg" /> */}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                }}
                            >
                                <img
                                    src={benefitInfo?.imageUrl}
                                    alt="logo"
                                    width={"60px"}
                                    height={"60px"}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                        margin={0}
                                    >
                                        {benefitInfo?.cardHeading}
                                    </Typography>
                                    <Typography
                                        variant="body3"
                                        margin={0}
                                        color={theme.palette.text.secondary}
                                    >
                                        {benefitInfo?.cardSubText}
                                    </Typography>
                                </Box>
                            </Box>
                            {(benefitInfo?.couponType ?? 0) > 0 && (
                                <>
                                    <Divider
                                        variant="dashed"
                                        sx={{
                                            marginBottom: "8px",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            height: "50px",
                                            padding: "16px",
                                            alignItems: "center",
                                            // justifyContent: "center",
                                            gap: "16px",
                                            alignSelf: "stretch",
                                            borderRadius: "20px",
                                            backgroundColor:
                                                revealed ||
                                                benefitInfo?.couponCode
                                                    ? theme.palette.grey[300]
                                                    : theme.palette.brand.main,
                                            cursor: "pointer",
                                            position: "relative",
                                        }}
                                    >
                                        {revealed || benefitInfo?.couponCode ? (
                                            <>
                                                <Stack
                                                    flexDirection={"row"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    width={"100%"}
                                                >
                                                    <Typography
                                                        variant="overline"
                                                        sx={{
                                                            color: theme.palette
                                                                .text.dark,
                                                            textTransform:
                                                                "uppercase",
                                                        }}
                                                    >
                                                        {couponCode ||
                                                            benefitInfo?.couponCode}
                                                    </Typography>
                                                </Stack>
                                                <Iconify
                                                    icon={
                                                        "material-symbols:content-copy-outline"
                                                    }
                                                    width={"24px"}
                                                    height={"24px"}
                                                    sx={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        copyFunction("IMT2.0");
                                                    }}
                                                    color={
                                                        theme.palette.text.dark
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <Typography
                                                variant="overline"
                                                sx={{
                                                    color: "#fff",
                                                    width: "100%",
                                                    textAlign: "center",
                                                    textTransform: "uppercase",
                                                }}
                                                onClick={() => {
                                                    handleCouponReveal();
                                                }}
                                            >
                                                Tap to reveal code
                                            </Typography>
                                        )}
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Stack flexDirection={"column"} gap={"8px"}>
                            <Typography
                                variant={isMobile ? "h5" : "subtitle2"}
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                                margin={0}
                            >
                                About the Offer
                            </Typography>
                            <Typography
                                variant="body3"
                                margin={0}
                                sx={{
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {healthOfferDescription}
                            </Typography>
                        </Stack>
                        {!isMobile && (
                            <>
                                <BadgeComponent
                                    text="FAQs"
                                    onClick={() =>
                                        navigate("/benefits/faqs", {
                                            state: { data: healthCareFAQs },
                                        })
                                    }
                                />
                                <BadgeComponent
                                    text="Terms & conditions"
                                    onClick={() => setOpen(true)}
                                />
                            </>
                        )}
                        {!isMobile &&
                            (revealed ||
                                benefitInfo?.couponCode ||
                                benefitInfo?.couponType === 0) && (
                                <LoadingButton
                                    variant="contained"
                                    loading={loading}
                                    size="small"
                                    sx={{
                                        background: theme.palette.brand.main,
                                        color: "#FFF",
                                        width: "100%",
                                        // position: "static",
                                        "@media (max-width: 600px)": {
                                            position: "fixed",
                                            bottom: 0,
                                            left: 0,
                                            width: "100%",
                                            zIndex: 1000,
                                        },
                                    }}
                                    onClick={() => handleRedeem()}
                                    endIcon={<ArrowRightAltIcon />}
                                >
                                    Avail Now
                                </LoadingButton>
                            )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "stretch",
                            alignItems: "flex-start",
                            width: "100%",
                            gap: "40px",
                            paddingTop: { xs: "0", sm: "24px" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "16px",
                                alignSelf: "stretch",
                            }}
                        >
                            <Typography
                                variant={isMobile ? "h5" : "subtitle2"}
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                                margin={0}
                            >
                                Steps to use
                            </Typography>
                            {healthCareOfferSteps?.map(
                                (step: { step: string }, index: number) => (
                                    <Stack
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        gap={"8px"}
                                        key={index}
                                    >
                                        <Typography
                                            sx={{
                                                display: "flex",
                                                width: "24px",
                                                height: "24px",
                                                padding: "1px 4px",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexShrink: 0,
                                                color: "#FFF",
                                                borderRadius: "500px",
                                                background:
                                                    theme.palette.grey[800],
                                                margin: "0",
                                            }}
                                            variant="subtitle2"
                                        >
                                            {index + 1}
                                        </Typography>

                                        <Typography
                                            key={index}
                                            variant="body3"
                                            sx={{
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                            margin={0}
                                        >
                                            {step.step.trim()}
                                        </Typography>
                                    </Stack>
                                )
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "16px",
                                alignSelf: "stretch",
                            }}
                        >
                            <Typography
                                variant={isMobile ? "h5" : "subtitle2"}
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                                margin={0}
                            >
                                Usage Condition
                            </Typography>
                            {healthCareRedeemSteps?.map(
                                (
                                    condition: {
                                        condition: string;
                                    },
                                    index
                                ) => (
                                    <Stack
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        gap={"8px"}
                                        key={index}
                                    >
                                        <InfoOutlined
                                            sx={{
                                                color: theme.palette.warning
                                                    .dark,
                                            }}
                                            fontSize="medium"
                                        />
                                        <Typography
                                            key={index}
                                            variant="body3"
                                            sx={{
                                                color: theme.palette.text
                                                    .primary,
                                            }}
                                            margin={0}
                                        >
                                            {condition?.condition?.trim()}
                                        </Typography>
                                    </Stack>
                                )
                            )}
                        </Box>
                        {benefitInfo?.partner !== "generalPartner" && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: "16px",
                                    alignSelf: "stretch",
                                }}
                            >
                                <Typography
                                    variant={isMobile ? "h5" : "subtitle2"}
                                    sx={{
                                        color: theme.palette.text.primary,
                                    }}
                                    margin={0}
                                >
                                    {`About ${benefitInfo?.partner}`}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    sx={{
                                        color: theme.palette.text.primary,
                                    }}
                                    margin={0}
                                >
                                    {vendorDescription}
                                </Typography>
                            </Box>
                        )}
                        {isMobile && (
                            <Stack
                                flexDirection={"column"}
                                width={"100%"}
                                spacing={2}
                            >
                                <BadgeComponent
                                    text="FAQs"
                                    onClick={() =>
                                        navigate("/benefits/faqs", {
                                            state: { data: healthCareFAQs },
                                        })
                                    }
                                />
                                <BadgeComponent
                                    text="Terms & conditions"
                                    onClick={() => setOpen(true)}
                                />
                            </Stack>
                        )}
                    </Box>
                </Box>
            </Box>
            {isMobile &&
                (revealed ||
                    benefitInfo?.couponCode ||
                    benefitInfo?.couponType === 0) && (
                    <Box
                        sx={{
                            padding: "12px 16px",
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            zIndex: 1000,
                            background: "#FFF",
                        }}
                        onClick={() => handleRedeem()}
                    >
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            size="small"
                            sx={{
                                background: theme.palette.brand.main,
                                color: "#FFF",
                                width: "100%",
                            }}
                            endIcon={
                                <Iconify icon="material-symbols:arrow-right-alt" />
                            }
                        >
                            Avail Now
                        </LoadingButton>
                    </Box>
                )}
            <Drawer
                open={open}
                onClose={() => {
                    handleClose();
                }}
                anchor={isMobile ? "bottom" : "right"}
                sx={{
                    "& .MuiDrawer-paper": {
                        padding: "24px 20px;",
                        display: "flex",
                        width: isMobile ? "100%" : "375px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flexShrink: 0,
                        borderRadius: {
                            xs: "24px 24px 0px 0px",
                            sm: "24px 0px 0px 24px",
                        },
                        backgroundColor: "#FFF",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        alignSelf: "stretch",
                    }}
                >
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.grey[800],
                            }}
                            margin={0}
                        >
                            Terms & Conditions
                        </Typography>
                        <Close
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                        />
                    </Stack>
                    <Divider
                        variant="dashed"
                        sx={{
                            borderColor: "grey.400",
                            opacity: 0.3,
                            marginBottom: "8px",
                        }}
                    />
                    <ol style={{ paddingLeft: "20px", margin: 0 }}>
                        {healthCareTnC?.map(
                            (
                                condition: {
                                    terms: string;
                                },
                                index
                            ) => (
                                <li
                                    key={index}
                                    style={{
                                        marginBottom: "8px",
                                    }}
                                >
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            color: "#000",
                                        }}
                                    >
                                        {condition.terms.trim()}
                                    </Typography>
                                </li>
                            )
                        )}
                    </ol>
                </Box>
            </Drawer>
        </>
    );
};

const BadgeComponent = ({
    text,
    onClick,
}: {
    text: string;
    onClick: Function;
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                padding: "var(--Spacing-2, 16px)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "16px",
                alignSelf: "stretch",
                borderRadius: "var(--Radius-3, 24px)",
                border: "var(--tag-badge-y-padding, 1px) solid var(--Components-Outline, #ECEFFF)",
                background: "#FFF",
                cursor: "pointer",
            }}
            onClick={() => onClick()}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%", // Ensure the children span the full width of the parent
                }}
            >
                <Typography variant="subtitle2" margin={0} color="textPrimary">
                    {text}
                </Typography>
                <IconButton
                    sx={{
                        border: "1px solid #ECEFFF",
                        borderRadius: "50%",
                        width: "28px",
                        height: "28px",
                        padding: "4px",
                    }}
                >
                    <ChevronRight
                        sx={{
                            color: theme.palette.grey[800],
                            width: "16px",
                            height: "16px",
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    );
};

export default BenefitView;
