import {
    Box,
    keyframes,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify";
import { useEffect } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

// create Faqs Mock Data

const Faqs = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();
    const location = useLocation();
    const healthCareFAQs = location?.state?.data;

    const theme = useTheme();

    const comeFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-50%);
  }
`;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);
    return (
        <Box
            sx={{
                // height: "450px",

                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",

                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate(-1)}
                    /> */}
                    <KeyboardBackspaceIcon
                        sx={{
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate(-1)}
                    />
                    <Typography
                        variant="h4"
                        sx={{ color: theme.palette.text.dark }}
                    >
                        FAQs
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    padding: { sm: "40px 32px", xs: "32px 20px" },
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignContent: "flex-start",
                    gap: { sm: "48px", xs: "40px" },
                    flexWrap: "wrap",
                    borderRadius: { sm: "48px", xs: "24px 24px 0 0" },
                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    minHeight: { xs: "100%", sm: "456px" },
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "",
                    },
                    margin: "auto",
                    // animation: `${comeFromRight} 0.5s ease-out forwards`,
                }}
            >
                {healthCareFAQs?.map(
                    (
                        faq: {
                            question: string;
                            answer: string;
                        },
                        index: number
                    ) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <Stack
                                flexDirection={"row"}
                                alignItems={"flex-start"}
                                gap={"8px"}
                            >
                                <Typography
                                    sx={{
                                        display: "flex",
                                        width: "24px",
                                        height: "24px",
                                        padding: "1px 4px",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexShrink: 0,
                                        color: "#FFF",
                                        borderRadius: "500px",
                                        background: "#0C1230",
                                        margin: "0",
                                    }}
                                    variant="subtitle1"
                                >
                                    {index + 1}
                                </Typography>
                                <Stack flexDirection={"column"}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.text.primary,
                                        }}
                                    >
                                        {faq.question}
                                    </Typography>
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.text.secondary,
                                        }}
                                    >
                                        {faq.answer}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                )}
            </Box>
        </Box>
    );
};

export default Faqs;
