import React, { useEffect, useState, useRef } from "react";
import Otp from "../../../components/Otp";
import advService from "../../../util/advService";
import { useStateValue } from "../../../util/stateProvider";
import {
    ONBOARD_POST,
    OTP_VERIFY_POST,
    OTP_POST,
    COMPANY_SPECIFIC_LOGIN_OTP,
} from "../../../util/newconfig";
import { JavaResponse } from "../../../types/JavaRespnse";
import { AxiosResponse } from "axios";
import { otpResponse } from "../../../types/DTO/otp";
import { setSignIn } from "../../../util/function";
import { useDispatch, useSelector } from "react-redux";
import {
    updateAuthStep,
    updateAuthStore,
} from "../../../features/auth/authSlice";
import { LoginResponse } from "../../../types/DTO/loginDTO";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { redirect } from "../../../util/function/redirect";
import checkResponseAndSetStep from "../../../util/function/checkResponse";
import ReactGA4 from "react-ga4";

type CallbackRef = () => void;

export function useInterval(callback: any, delay: number) {
    const savedCallback = useRef<CallbackRef | null>(null);
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        function tick() {
            if (savedCallback && savedCallback.current) {
                savedCallback.current(); // should work now
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

interface CommonOtpProps {
    setHeader: any;
    otpType: any;
    setLoader: any;
    setStack: any;
    resendSeconds: any;
    setResendSeconds: any;
}

const CommonOtp = ({
    setHeader,
    otpType,
    setLoader,
    setStack,
    resendSeconds,
    setResendSeconds,
}: CommonOtpProps) => {
    const reducerDispatch = useDispatch();
    const { authStore } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    useEffect(() => {
        setHeader("Please Enter OTP for verification");
        setStack((i: any) => {
            console.log(i);
            let c = i.slice();
            if (otpType === "loginOtp") c.splice(c.length, 0, "otp");
            else if (otpType === "onBoard")
                c.splice(c.length, 0, "onBoardVerifyOtp");
            else if (otpType === "multipleLookupOtp")
                c.splice(c.length, 0, "multipleLookupOtp");
            if (i[i.length - 1] === c[c.length - 1]) return i;
            return c;
        });
    }, [setHeader, setStack, otpType]);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [inputCompleted, setInputCompleted] = useState(false);
    const [resendOtpAllowed, setResendOtpAllowed] = useState(false);
    const [timer, setTimer] = useState(true);
    const [{ user }, dispatch] = useStateValue();

    useEffect(() => {
        let headers = {
            Authorization: authStore.authorization,
        };
        if (inputCompleted) {
            if (otpType === "onBoard") {
                let payload = {
                    memberUIDTO: {
                        ...authStore?.onBoard,
                    },
                    loginDTO: {
                        username: authStore?.onBoard?.askPhone,
                        phone: true,
                        otp,
                    },
                };
                let config = { ...ONBOARD_POST };
                config.setLoader = setLoader;
                advService(
                    config,
                    payload,
                    async (res: AxiosResponse<JavaResponse<LoginResponse>>) => {
                        if (res?.data?.success) {
                            let newRes:
                                | boolean
                                | AxiosResponse<JavaResponse<LoginResponse>> =
                                checkResponseAndSetStep(res, reducerDispatch);
                            if (newRes) {
                                let response = newRes.data.response;
                                setSignIn(
                                    newRes.headers.authorization,
                                    response?.user || null,
                                    response.lead || null,
                                    response.page === "leadView"
                                        ? null
                                        : response.uuid ||
                                              response.user?.uuid ||
                                              null,
                                    false,
                                    dispatch
                                );

                                const userProperties = {
                                    userType: response?.user.userType,
                                    userName: response?.user.personName,
                                    userId: response?.user.userId,
                                };

                                ReactGA4.set({
                                    user_properties: userProperties,
                                });

                                redirect(newRes, navigate);
                            }
                        }
                    },
                    headers
                );
            } else if (
                otpType === "loginOtp" ||
                otpType === "multipleLookupOtp" ||
                otpType === "companySpecificLogInOtp"
            ) {
                let config = {
                    ...(otpType === "companySpecificLogInOtp"
                        ? COMPANY_SPECIFIC_LOGIN_OTP
                        : OTP_VERIFY_POST),
                };

                interface LoginPayload {
                    username: string;
                    phone: boolean;
                    otp: number;
                    employeeId?: string;
                    uuid?: string;
                    dob?: string;
                }

                let payload: LoginPayload = {
                    username: authStore.username,
                    phone: authStore.isEmail === "0",
                    otp: Number(otp),
                };

                if (otpType === "companySpecificLogInOtp") {
                    config = {
                        ...config,
                        url: config.url.replace("{uuid}", authStore?.uuid),
                    };

                    payload = {
                        ...payload,
                        employeeId: authStore.employeeId,
                        uuid: authStore.uuid,
                        dob: authStore.dob,
                    };
                }

                config.setLoader = setLoader;
                advService(
                    config,
                    payload,
                    async (res: AxiosResponse<JavaResponse<LoginResponse>>) => {
                        console.log("Response : ", res);
                        if (res?.data?.success) {
                            // TODO: what if user is not present and page is multipleLookup/ user is USER then what is the purpose of setSignIn
                            let newRes:
                                | boolean
                                | AxiosResponse<JavaResponse<LoginResponse>> =
                                checkResponseAndSetStep(res, reducerDispatch);

                            if (newRes) {
                                let response = newRes.data.response;
                                setSignIn(
                                    newRes.headers.authorization,
                                    response?.user || null,
                                    response.lead || null,
                                    response.page === "leadView"
                                        ? null
                                        : response.uuid ||
                                              response.user?.uuid ||
                                              null,
                                    response?.d_verify_phone,
                                    dispatch
                                );
                                const userProperties = {
                                    userType: response?.user.userType,
                                    userName: response?.user.personName,
                                    userId: response?.user.userId,
                                };

                                ReactGA4.set({
                                    user_properties: userProperties,
                                });
                                redirect(newRes, navigate);
                            }
                        } else {
                            setResendSeconds(0);
                            setTimer(false);
                            setResendOtpAllowed(true);
                            setOtpError(true);
                        }
                    },
                    headers
                );
            }
            setInputCompleted(false);
        }
    }, [
        inputCompleted,
        redirect,
        otp,
        authStore,
        otpType,
        dispatch,
        setLoader,
    ]);

    /*useEffect(() => {
    if (!resendOtpAllowed && !timer) {
      let interval = setInterval(() => {
        setResendSeconds((i) => i + 1);
      }, 1000);
      setTimer(interval);
    }
  }, [resendOtpAllowed, timer, setResendSeconds]);

  useEffect(() => {
    if (resendSeconds === 30) {
      setResendOtpAllowed(true);
      setResendSeconds(0);
      clearInterval(timer);
      setTimer(false);
    }
    console.log(resendSeconds);
  }, [resendSeconds, timer, setResendSeconds]);*/
    useInterval(() => {
        // console.log('setting',resendSeconds);
        if (!timer) return;
        if (resendSeconds === 30) {
            setResendSeconds(0);
            setResendOtpAllowed(true);
            setTimer(false);
        }
        setResendSeconds((i: number) => i + 1);
    }, 1000);

    const resendOtp = () => {
        setOtpError(false);
        if (resendOtpAllowed) {
            let config = { ...OTP_POST };
            let payload =
                otpType === "onBoard"
                    ? {
                          username: authStore.username,
                          phone: authStore.isEmail === "0",
                      }
                    : otpType === "loginOtp"
                    ? {
                          username: authStore.username,
                          phone: authStore.isEmail === "0",
                      }
                    : {
                          username: authStore.phone,
                          phone: authStore.isEmail === "0",
                      };
            config.setLoader = setLoader;
            advService(
                config,
                payload,
                (_res: AxiosResponse<JavaResponse<otpResponse>>) => {
                    setTimer(true);
                    setResendOtpAllowed(false);
                }
            );
        }
    };
    return (
        <div>
            <form
                className="elementContainer"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (otp.length === 4 && Number(otp)) {
                        setInputCompleted(true);
                    }
                }}
            >
                <Otp
                    onChange={(otp: string) => {
                        setOtpError(false);
                        setOtp(otp);
                        if (otp.length === 4 && Number(otp))
                            setInputCompleted(true);
                    }}
                    error={otpError}
                    className="mb25"
                />
                <button className="buttonPrimary">Submit</button>
            </form>
            {resendOtpAllowed ? (
                <div
                    onClick={resendOtp}
                    style={{
                        cursor: "pointer",
                        textAlign: "center",
                        marginTop: "20px",
                        color: "black",
                    }}
                >
                    Resend Otp
                </div>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    Resend Otp in 00:
                    {(String(30 - resendSeconds).length === 1 ? "0" : "") +
                        String(30 - resendSeconds)}
                </div>
            )}
            {otpType === "loginOtp" ? (
                <div
                    className="pt25 textCenter primaryColor pointer"
                    onClick={() => reducerDispatch(updateAuthStep("password"))}
                >
                    Login via Password
                </div>
            ) : null}
        </div>
    );
};

export default CommonOtp;
