import { HealthCheckup } from "../../services/api/healthCheckup/healthCheckup";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: HealthCheckup = {
    packages: [],
    booking: {
        packages: {},
        address: {
            uid: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            pincode: 0,
            houseNumber: "",
            appartmentName: "",
            landmark: "",
            type: "Home",
        },
        schedule: {
            date: "",
            time: "",
        },
        contact: {
            name: "",
            email: "",
            phone: "",
        },
    },
    members: [],
    savedAddressList: [
        {
            uid: "01",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Home",
        },
        {
            uid: "1",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "2",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "3",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "4",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "5",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "6",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "7",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
        {
            uid: "8",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            city: "City",
            state: "State",
            pincode: 123456,
            houseNumber: "House Number",
            appartmentName: "Appartment Name",
            landmark: "Landmark",
            type: "Office",
        },
    ],
};

const healthCheckupSlice = createSlice({
    name: "healthCheckup",
    initialState,
    reducers: {
        setHealthCheckupPackages: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["packages"]>
        ) => {
            state.packages = action.payload;
        },
        setHealthCheckupBooking: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]>
        ) => {
            state.booking = action.payload;
        },
        setHealthCheckupMembers: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["members"]>
        ) => {
            state.members = action.payload;
        },
        setHealthCheckupBookingContact: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]["contact"]>
        ) => {
            state.booking.contact = action.payload;
        },
        setHealthCheckupBookingSchedule: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["booking"]["schedule"]>
        ) => {
            state.booking.schedule = action.payload;
        },
        setHealthCheckupSavedAddressList: (
            state: HealthCheckup,
            action: PayloadAction<HealthCheckup["savedAddressList"]>
        ) => {
            state.savedAddressList = action.payload;
        },
    },
});

// export const { setCustomerCareData } = customerCareSlice.actions;
export const {
    setHealthCheckupPackages,
    setHealthCheckupBooking,
    setHealthCheckupMembers,
    setHealthCheckupBookingContact,
    setHealthCheckupBookingSchedule,
    setHealthCheckupSavedAddressList,
} = healthCheckupSlice.actions;

export default healthCheckupSlice.reducer;
