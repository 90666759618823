import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PolicyMembers = {
    sumInsured?: string;
    dob?: string;
    name: string;
    claimable?: string;
    relation: string;
    deductible?: string;
    relationDp: string;
    healthCardId?: string;
    uid?: string;
    policyType?: string;
    dom?: string;
    employeeId?: string;
    relation_ship?: string;
    gender?: string;
    coverageStartDate?: string;
};
export interface PolicyState {
    policyCardDto: {
        policyCards:
            | {
                  insuranceCategory: string;
                  heading: string;
                  endDate?: string;
                  dateAlter?: {
                      daysRemaining: number;
                      color: "primary" | "secondary" | "error" | "success";
                  };
                  pId: number;
                  bgImage: string;
                  uuid: string;
                  categoryLogo: string;
                  covers?: string;
              }[]
            | null;
    } | null;
    policyDetailDto: {
        insuranceCategory: string;

        detailedInfo: {
            policyTypeDp?: string;
            policyType?: string;
            isEdit?: boolean;
            vehicleType?: string;
            claimableTotal?: string;
            deductibleTotal?: string;
            locationTotal?: {
                stockSiTotal?: string;
                otherSiTotal?: string;
                buildingSiTotal?: string;
                fffSiTotal?: string;
                pmSiTotal?: string;
            };
            location?: {
                fffSi?: string;
                pincode?: string;
                address?: string;
                stockSi?: string;
                pmSi?: string;
                otherSi?: string;
                location?: string;
                buildingSi?: string;
            }[];
            ownerName?: string;
            registrationNumber?: string;
            sumInsuredTotal?: string;
            model?: string;
            idv?: string;
            make?: string;
            covers?: string;
            members?: PolicyMembers[];

            isAdd?: boolean;
            policyValidation?: {
                ageValidation: {
                    EmployeeChild: {
                        minAge: number;
                        maxAge: number;
                    };
                    Spouse: {
                        minAge: number;
                        maxAge: number;
                    };
                    Parent: {
                        minAge: number;
                        maxAge: number;
                    };
                    EmployeeParent: {
                        minAge: number;
                        maxAge: number;
                    };
                    Child: {
                        minAge: number;
                        maxAge: number;
                    };
                    Employee: {
                        minAge: number;
                        maxAge: number;
                    };
                };
                family: {
                    numberOfChild: number;
                    numberOfParent: number;
                    spouse: boolean;
                    employee: boolean;
                    liveInPartner: boolean;
                    parent: number;
                    ebAttributeLabel: string;
                };
                demography: number;
                basePolicyRestriction: boolean;
                topupAllOptions: boolean;
                nomination: boolean;
            };
        };
        coverage?: boolean;
        topInfo: {
            isPolicyDownload?: boolean;
            heading: string;
            tpa?: string;
            endDate?: string;
            insurer: string;
            companyLogo: string;
            policyNumber?: string;
            isFloater?: boolean;
            startDate?: string;
            policyType?: string;
        };
        networkHospital?: {
            value: string;
            label: string;
        };
        networkGarage?: {
            value: string;
            label: string;
        };
    } | null;
}

const initialState: PolicyState = {
    policyCardDto: null,
    policyDetailDto: null,
};

const policySlice = createSlice({
    name: "policy",
    initialState,
    reducers: {
        setPolicyCardDto: (
            state: PolicyState,
            action: PayloadAction<PolicyState["policyCardDto"]>
        ) => {
            state.policyCardDto = action.payload;
        },
        setPolicyDetailDto: (
            state: PolicyState,
            action: PayloadAction<PolicyState["policyDetailDto"]>
        ) => {
            state.policyDetailDto = action.payload;
        },
    },
});

export const { setPolicyCardDto, setPolicyDetailDto } = policySlice.actions;

export default policySlice.reducer;
