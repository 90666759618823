import {
    Box,
    CircularProgress,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Popover,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Iconify from "../../components/iconify/iconify";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL, HOSPITAL } from "../../util/config";
import service from "../../util/axiosConfig";
import { useQuery } from "react-query";
import copyFunction from "../../util/function/copyFunction";
import openIphoneLink from "../../util/function/openIphoneLink";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const searchParameters = ["City", "Hospital Name", "Pincode"];

type Hospital = {
    hospitalId: string;
    name: string;
    address: string;
    email: string;
    phoneno: string;
    city: string;
    state: string;
    pincode: string;
};

const isNumber = (value: string): boolean => {
    return !isNaN(Number(value));
};

const NewNetworkHospitals = () => {
    const [currentParameter, setCurrentParameter] = useState(0);
    const isMobile = useMediaQuery("(max-width:600px)");

    const theme = useTheme();

    const { uuid, pid } = useParams<{ uuid: string; pid: string }>();

    const user = JSON.parse(sessionStorage.getItem("user") as string);

    const [blacklistLink, setblacklistLink] = useState<string | null>(null);

    const [hospitalList, setHospitalList] = useState<Hospital[]>([]);

    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");

    const [cityList, setCityList] = useState<string[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const boxRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get("from");

    const policyDetailDto = useSelector(
        (state: RootState) => state.policy.policyDetailDto
    );

    const open = Boolean(anchorEl);

    function getBlacklistLink(policyID: string) {
        return new Promise(async (resolve, reject) => {
            if (policyID) {
                try {
                    const res = await service.get(
                        `${BASE_URL}v1/hospital/${uuid}/${policyID}/blacklist`
                    );
                    if (res?.data?.success) {
                        const blacklistLink =
                            res.data.response.d_blacklist_network_hospital_url;
                        resolve(blacklistLink);
                    } else {
                        reject("Failed to fetch blacklist link");
                    }
                } catch (error) {
                    reject(error);
                }
            } else {
                reject("Invalid policyID");
            }
        });
    }

    const { data: blacklistLinkData, isSuccess } = useQuery(
        ["blacklistLink", pid],
        () => getBlacklistLink(pid as string),

        {
            enabled: !!pid && user.userType === "EMPLOYEE",
            staleTime: 60000,
            retry: 2,
        }
    );

    const searchHospitalList = (label: string, value: string) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");

        let query = `${HOSPITAL}${
            uuid || sessionStorage.getItem("uuid")
        }/${pid}/q?${label}=${sanitizedValue}`;

        if (sanitizedValue?.length > 2) {
            setLoading(true);
            service
                .get(query)
                .then((res) => {
                    // setSearched(true);
                    if (res.data.success) {
                        setHospitalList(res.data.response.d_hospitals);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const searchCityList = (cityName: string) => {
        const sanitizedValue = cityName.replace(/[^a-zA-Z0-9]/g, "");

        let updatedString = sanitizedValue.replace(/\\/g, "");
        if (updatedString.length < 3) {
            return;
        }
        service
            .get(
                `${HOSPITAL}${
                    uuid || sessionStorage.getItem("uuid")
                }/${pid}/city?name=${updatedString}`
            )
            .then((res) => {
                if (res.data.success) {
                    setCityList(res.data.response?.d_cities);
                }
            });
    };

    const debounce = (func: (...args: any[]) => void, wait: number) => {
        let timeout: NodeJS.Timeout;

        const debouncedFunction = (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                func(...args);
            }, wait);
        };

        debouncedFunction.cancel = () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };

        return debouncedFunction;
    };

    const debouncedSearchHospitalList = useCallback(
        debounce(searchHospitalList, 300),
        []
    );
    const debouncedSearchCityList = useCallback(
        debounce(searchCityList, 300),
        []
    );

    const handleHospitalSearchChange = (label: string, value: string) => {
        debouncedSearchHospitalList(label, value);
    };

    const handleCitySearchChange = (cityName: string) => {
        debouncedSearchCityList(cityName);
    };

    const handlePopoverOpen = () => {
        if (cityList.length > 0) {
            setAnchorEl(document.querySelector("#search-box") as HTMLElement);
        } else {
            setAnchorEl(null);
        }
    };

    useEffect(() => {
        handlePopoverOpen();
    }, [cityList]);

    useEffect(() => {
        if (searchText === "") {
            setCityList([]);
            setHospitalList([]);
            return;
        }
    }, [searchText]);

    useLayoutEffect(() => {
        if (isSuccess) {
            setblacklistLink(blacklistLinkData as string);
        }
    }, [pid, blacklistLinkData, isSuccess]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentParameter((prev) => (prev + 1) % searchParameters.length);
        }, 1500);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    width: "100%",
                    // justifyContent: "center",
                    alignItems: "center",
                    height: { xs: "100%", sm: "" },
                    flexDirection: "column",
                    gap: { sm: "24px", xs: "20px" },
                    transition: "width 0.3s ease-in-out",
                    minHeight: { xs: "100%", sm: "inherit" },
                }}
            >
                <Box
                    id="search-box"
                    sx={{
                        display: "flex",
                        width: { sm: "50%", xs: "100%" },
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "54px",
                        padding: "0px 20px",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "8px",
                        alignSelf: "stretch",
                        borderRadius: "46px",
                        border: "1px solid #7F85A3",
                        "&:focus-within": {
                            border: `1px solid ${theme.palette.brand.main}`,
                        },
                    }}
                    ref={boxRef}
                >
                    <Iconify
                        icon={"material-symbols:search"}
                        width={"24px"}
                        height={"24px"}
                        color={"#464D6A"}
                    />

                    <TextField
                        variant="outlined"
                        placeholder={`Search by ${searchParameters[currentParameter]}`}
                        value={searchText}
                        onFocus={handlePopoverOpen}
                        onChange={(e) => {
                            setSearchText(e.target.value);

                            if (
                                e.target.value.length === 6 &&
                                isNumber(e.target.value)
                            ) {
                                handleHospitalSearchChange(
                                    "pincode",
                                    e.target.value
                                );
                            } else {
                                handleHospitalSearchChange(
                                    "name",
                                    e.target.value
                                );
                            }
                            handleCitySearchChange(e.target.value);
                        }}
                        sx={{
                            flex: 1,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                                "& input::placeholder": {
                                    color: theme.palette.text.primary,
                                },
                            },
                        }}
                    />

                    <Iconify
                        icon={"material-symbols:cancel-outline"}
                        width={"24px"}
                        height={"24px"}
                        color={"#464D6A"}
                        sx={{
                            cursor: "pointer",
                        }}
                        onClick={() => setSearchText("")}
                    />
                </Box>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    disableEnforceFocus
                    disableAutoFocus
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    sx={{
                        "& .MuiPaper-root": {
                            width: boxRef.current
                                ? boxRef.current.clientWidth
                                : "auto",
                            // padding: "20px",
                            borderRadius: "0 0 16px 16px",
                            maxHeight: "250px",
                        },
                    }}
                    className="surface2"
                >
                    <List
                        subheader={
                            <ListSubheader component="div">Cites</ListSubheader>
                        }
                    >
                        {cityList?.map((city, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleHospitalSearchChange("city", city);
                                    setCityList([]);
                                }}
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="subtitle2"
                                            color={theme.palette.text.primary}
                                        >
                                            {city}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Popover>
                <Stack
                    flexDirection={"row"}
                    justifyContent={
                        hospitalList?.length > 0 ? "space-between" : "center"
                    }
                    alignItems={"center"}
                    width={"100%"}
                >
                    {hospitalList?.length > 0 && (
                        <Typography
                            variant="h6"
                            color={theme.palette.text.primary}
                            sx={{
                                alignSelf: "flex-start",
                            }}
                        >
                            {`${hospitalList?.length ?? 0} Hospitals`}
                        </Typography>
                    )}
                    {blacklistLink && (
                        <Typography
                            variant="overline"
                            sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: theme.palette.text.primary,
                            }}
                            onClick={() => {
                                const isIOS = /iPad|iPhone|iPod/.test(
                                    navigator.userAgent
                                );
                                if (isIOS) {
                                    openIphoneLink(blacklistLink as string);
                                } else {
                                    window.open(
                                        blacklistLink as string,
                                        "_blank"
                                    );
                                }
                            }}
                        >
                            Black listed hospitals
                            <Iconify
                                sx={{
                                    marginLeft: "8px",
                                }}
                                icon={"material-symbols:arrow-right-alt"}
                            />
                        </Typography>
                    )}
                </Stack>

                {loading ? (
                    <div
                        style={{
                            width: "100%",
                            height: "40vh",
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                        }}
                    >
                        <CircularProgress
                            style={{
                                alignSelf: "center",
                                justifySelf: "center",
                            }}
                        />
                    </div>
                ) : (
                    hospitalList?.length > 0 && (
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    sm: "1fr 1fr",
                                    xs: "1fr",
                                },
                                gap: { xs: "16px", sm: "24px" },
                                width: "100%",
                            }}
                        >
                            {hospitalList?.map((hospital, index) => (
                                <HospitalCard key={index} hospital={hospital} />
                            ))}
                        </Box>
                    )
                )}
                {!((hospitalList?.length ?? 0) > 0) && !loading && (
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            // marginTop: { xs: "40%", sm: "0%" },
                            // minHeight: {
                            //     xs: "200px",
                            //     sm: "300px",
                            //     md: "400px",
                            // },
                        }}
                    >
                        <img
                            alt="search"
                            width={"140px"}
                            height={"140px"}
                            src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Find+hopsital.png"
                        />
                    </Box>
                )}
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap="16px"
                    flexDirection={"row"}
                    // marginTop="auto"
                    sx={{
                        marginTop: "auto",
                        width: "100%",
                        justifyContent: { xs: "center", sm: "flex-end" },
                        // paddingRight: { sm: "32px" },
                    }}
                >
                    <Typography
                        variant="body3"
                        color={theme.palette.text.secondary}
                    >
                        Brought to you by
                    </Typography>
                    {policyDetailDto?.topInfo?.tpa ? (
                        policyDetailDto?.topInfo?.tpa ===
                        policyDetailDto?.topInfo?.insurer ? (
                            <Box
                                component={"img"}
                                src={policyDetailDto?.topInfo?.companyLogo}
                                width={"68px"}
                            />
                        ) : (
                            <Typography
                                variant="subtitle2"
                                color={theme.palette.text.primary}
                            >
                                {policyDetailDto?.topInfo?.tpa}
                            </Typography>
                        )
                    ) : (
                        <Box
                            component={"img"}
                            src={policyDetailDto?.topInfo?.companyLogo}
                            width={"68px"}
                        />
                    )}
                </Stack>
            </Box>
        </>
    );
};

export default NewNetworkHospitals;

function HospitalCard({ hospital }: { hospital: Hospital }) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                width: { sm: "349px", xs: "100%" },
                padding: "20px 16px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                borderRadius: "24px",
                justifyContent: "space-between",
                border: "1px solid var(--Components-Outline, #ECEFFF)",
                background: "#FFF",
                // boxShadow: "0px 2px 6px 0px rgba(39, 57, 147, 0.06)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "var(--Card-Content, 8px)",
                    alignSelf: "stretch",
                }}
            >
                <Typography variant="subtitle2">{hospital?.name}</Typography>
                <Typography variant="body2">
                    {[
                        hospital?.address,
                        hospital?.city,
                        hospital?.state,
                        hospital?.pincode,
                    ]
                        .filter(Boolean)
                        .join(", ")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignContent: "flex-start",
                    gap: "8px",
                    alignSelf: "stretch",
                    flexWrap: "wrap",
                    width: "100%",
                }}
            >
                <Iconify
                    icon={"material-symbols:share"}
                    color={theme.palette.brand.main}
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        window.open(
                            `https://wa.me/?text=${encodeURI(
                                `Hospital Name: ${hospital.name}
Address: ${hospital.address}
Phone: ${hospital.phoneno}
City: ${hospital.city}
State: ${hospital.state}
Pincode: ${hospital.pincode}`
                            )}`,
                            "_blank"
                        );
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        width: "28px",
                        height: "28px",
                        padding: "6px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "500px",
                        border: "1px solid rgba(255, 255, 255, 0.00)",
                        background: theme.palette.brand.main,
                        boxShadow: "0px 4px 10px 0px rgba(74, 58, 255, 0.06)",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        copyFunction(`hospital:${hospital.name}, 
                               hospitalId:${hospital.hospitalId}, 
                               city:${hospital.city},
                               phone:${hospital.phoneno},
                               address:${hospital.address}`);
                    }}
                >
                    <Iconify
                        icon={"material-symbols:content-copy-outline"}
                        color={"white"}
                    />
                </Box>
            </Box>
        </Box>
    );
}
