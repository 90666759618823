import {
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { EnrollmentState, MultiStepFormState } from "../../../types";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Iconify from "../../../components/iconify";
import GenericModalContainer from "../../../components/Modals/GenericModalContainer";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import { TimelineConnector, TimelineContent } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const EnrollmentStep1 = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const [showBenifits, setShowBenifits] = useState(false);
    const [showHowItWorks, setShowHowItWorks] = useState(false);
    const { homeDtoV2 } = useSelector((state: RootState) => state.home);
    const expiryDate = new Date(homeDtoV2?.d_enrollment?.endDate ?? "");
    const currentDate = new Date();
    const timeDifference = expiryDate.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));

    const EnrollmentHeroImg =
        "https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Enrolmentimage.png";

    return (
        <Box
            sx={{
                // height: "450px",

                // paddingTop: { sm: "33px", xs: "" },
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                // backgroundPosition: "center",
                // margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                // gap: { sm: "36px", xs: "0px" },
                // padding: { xs: "0px 20px", md: "0px" },
            }}
        >
            <Stack
                justifyContent="space-between"
                direction="row"
                width={"100%"}
                sx={{
                    display: "flex",
                    width: { xs: "100%", md: "770px" },
                    position: "relative",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    margin: "auto",
                }}
            >
                <Stack
                    sx={{
                        width: {
                            xs: "100%",
                            md: "456px",
                        },
                        marginTop: "48px",
                    }}
                    spacing={"24px"}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: "36px !important",
                        }}
                    >
                        Enrollment drive
                    </Typography>
                    {isMobile && (
                        <img
                            src={EnrollmentHeroImg}
                            style={{
                                width: "161px",
                                alignSelf: "center",
                            }}
                        />
                    )}
                    <Chip
                        label={`Drive ends in ${daysLeft} days`}
                        color="error"
                        sx={{
                            height: "22px",
                            backgroundColor: theme.palette.error.lighter,
                            color: theme.palette.error.dark,
                            width: "fit-content",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "48px !important",
                        }}
                    />
                    <Typography variant="h4">
                        Enroll your family under insurance coverage hosted by
                        your employer
                    </Typography>
                    <Button
                        onClick={() => {
                            navigate("/newEnrollment");
                        }}
                        sx={{
                            backgroundColor: theme.palette.brand.main,
                            color: "#ffffff",
                            width: {
                                md: "fit-content",
                                xs: "100%",
                            },
                            height: "42px",
                        }}
                    >
                        Start enrolling
                    </Button>
                </Stack>
                <Box
                    sx={{
                        // width: "274px !important",
                        width: {
                            md: "274px",
                            xs: "100% ",
                        },
                        marginTop: {
                            md: "128px !important",
                            xs: "0px",
                        },
                    }}
                >
                    {!isMobile && (
                        <img
                            src={EnrollmentHeroImg}
                            style={{
                                width: "161px",
                                margin: "18px 57px",
                            }}
                        />
                    )}
                    <Typography
                        variant="subtitle1"
                        sx={{
                            marginTop: "48px",
                        }}
                    >
                        You may be looking for
                    </Typography>
                    <Stack
                        sx={{
                            padding: "16px",
                            marginTop: "24px",
                            borderRadius: "12px",
                            border: `1px solid ${theme.palette.grey[300]}`,
                        }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        onClick={() => {
                            setShowBenifits(!showBenifits);
                        }}
                    >
                        <Typography variant="subtitle2">
                            Benefits of enrolling my family ?
                        </Typography>
                        <IconButton
                            sx={{
                                height: "36px",
                                width: "36px",
                                padding: "0",
                                border: "1px solid var(--Components-Outline, #EAEAEF)",
                            }}
                        >
                            <Iconify icon="material-symbols:chevron-right" />
                        </IconButton>
                    </Stack>
                    <Stack
                        sx={{
                            padding: "16px",
                            marginTop: "24px",
                            borderRadius: "12px",
                            border: `1px solid ${theme.palette.grey[300]}`,
                        }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        onClick={() => {
                            setShowHowItWorks(!showHowItWorks);
                        }}
                    >
                        <Typography variant="subtitle2">
                            How it works ?
                        </Typography>
                        <IconButton
                            sx={{
                                height: "36px",
                                width: "36px",
                                padding: "0",
                                border: "1px solid var(--Components-Outline, #EAEAEF)",
                            }}
                        >
                            <Iconify icon="material-symbols:chevron-right" />
                        </IconButton>
                    </Stack>
                </Box>
            </Stack>
            {showBenifits && (
                <GenericModalContainer
                    close={() => setShowBenifits(false)}
                    headingText="Benefits of enrolling my family ?"
                >
                    <Stack>
                        <Divider
                            variant="dashed"
                            sx={{
                                borderColor: "grey.400",
                                opacity: 0.3,
                                marginBottom: "8px",
                            }}
                        />
                        <Box
                            sx={{
                                padding: {
                                    xs: "0px 16px",
                                    md: "0px 20px",
                                },
                            }}
                        >
                            <ul
                                style={
                                    {
                                        // make dots theme.text.primary
                                    }
                                }
                            >
                                <li>
                                    <Typography variant="subtitle2">
                                        Comprehensive Coverage
                                    </Typography>
                                    <Typography variant="body2">
                                        Ensure your loved ones are protected
                                        with the comprehensive health coverage
                                        you receive.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        Family Health Insurance
                                    </Typography>
                                    <Typography variant="body2">
                                        Enroll your family in the group health
                                        insurance plan to provide them with
                                        access to quality healthcare services.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        Preventive Care
                                    </Typography>
                                    <Typography variant="body2">
                                        Access preventive care services to
                                        maintain overall health and well-being.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        Financial Protection
                                    </Typography>
                                    <Typography variant="body2">
                                        Be financially protected during medical
                                        emergencies with the coverage provided
                                        by the group health insurance plan.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </Stack>
                </GenericModalContainer>
            )}
            {showHowItWorks && (
                <GenericModalContainer
                    close={() => setShowHowItWorks(false)}
                    headingText="How it works ?"
                >
                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            padding: 0,
                        }}
                    >
                        <TimelineItem>
                            <TimelineSeparator>
                                <CheckCircleIcon
                                    sx={{
                                        color: theme.palette.brand.main,
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                                <TimelineConnector
                                    sx={{
                                        border: `1px dashed ${theme.palette.grey[300]}`,
                                        background: "none",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    paddingTop: 0,
                                }}
                            >
                                <Typography variant="subtitle2">
                                    Select family members
                                </Typography>
                                <Typography variant="body2">
                                    Simply select the family members you'd like
                                    to enroll
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                <CheckCircleIcon
                                    sx={{
                                        color: theme.palette.brand.main,
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                                <TimelineConnector
                                    sx={{
                                        border: `1px dashed ${theme.palette.grey[300]}`,
                                        background: "none",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    paddingTop: 0,
                                }}
                            >
                                <Typography variant="subtitle2">
                                    Confirm Details
                                </Typography>
                                <Typography variant="body2">
                                    Provide the necessary information for each
                                    family member.{" "}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                <CheckCircleIcon
                                    sx={{
                                        color: theme.palette.brand.main,
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                                <TimelineConnector
                                    sx={{
                                        border: `1px dashed ${theme.palette.grey[300]}`,
                                        background: "none",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    paddingTop: 0,
                                }}
                            >
                                <Typography variant="subtitle2">
                                    Select coverages
                                </Typography>
                                <Typography variant="body2">
                                    You can choose multiple coverages (Subject
                                    to availability) for your family members{" "}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                <CheckCircleIcon
                                    sx={{
                                        color: theme.palette.brand.main,
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                                <TimelineConnector
                                    sx={{
                                        border: `1px dashed ${theme.palette.grey[300]}`,
                                        background: "none",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    paddingTop: 0,
                                }}
                            >
                                <Typography variant="subtitle2">
                                    Submit enrolment
                                </Typography>
                                <Typography variant="body2">
                                    Submit the completed enrollment form.{" "}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                <CheckCircleIcon
                                    sx={{
                                        color: theme.palette.brand.main,
                                        height: "20px",
                                        width: "20px",
                                    }}
                                />
                            </TimelineSeparator>
                            <TimelineContent
                                sx={{
                                    paddingTop: 0,
                                }}
                            >
                                <Typography variant="subtitle2">
                                    Coverage Activation
                                </Typography>
                                <Typography variant="body2">
                                    Once enrolled, your family members' coverage
                                    will be activated under your employer's
                                    group health insurance plan.{" "}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </GenericModalContainer>
            )}
        </Box>
    );
};

export default EnrollmentStep1;
