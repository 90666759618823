import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useEffect, useState } from "react";
import NewAddon from "../Home/NewAddon";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify/iconify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const BenefitsPage = () => {
    const [selectedChip, setSelectedChip] = useState("Active");

    const { homeDtoV2 } = useSelector((state: RootState) => state.home);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const handleChipClick = (label: string) => {
        setSelectedChip(label);
    };

    const navigate = useNavigate();

    const theme = useTheme();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    return (
        <Box
            sx={{
                // height: "450px",

                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    {/* <Iconify
                        icon="material-symbols:arrow-left-alt"
                        sx={{
                            // marginTop: "5px",
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                        }}
                        width={"32px"}
                        height={"32px"}
                        onClick={() => navigate("/home")}
                    /> */}
                    <KeyboardBackspaceIcon
                        sx={{
                            color: theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={() => navigate("/home")}
                    />
                    <Stack direction="column" gap="4px">
                        <Typography
                            variant="h4"
                            sx={{ color: theme.palette.text.dark }}
                        >
                            Wellness & care
                        </Typography>
                        <Typography
                            variant="body3"
                            sx={{ color: theme.palette.text.primary }}
                        >
                            Because we care for you
                        </Typography>
                    </Stack>
                </Box>
                {/* <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Chip
                            label="Active"
                            onClick={() => handleChipClick("Active")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Active"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Active"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                        <Chip
                            label="Buy Benefits"
                            onClick={() => handleChipClick("Buy Benefits")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Buy Benefits"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Buy Benefits"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                    </Box> */}
            </Box>
            {isMobile && (
                <img
                    src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Wellness+BG+illustration.png"
                    alt="bgLogo"
                    width="126px"
                    height="137px"
                    style={{
                        position: "absolute",
                        bottom: isMobile ? "" : "198px",
                        top: isMobile ? "50px" : "",
                        right: 0,
                    }}
                />
            )}
            <Box
                sx={{
                    display: "grid",
                    width: { xs: "100%", sm: "770px" },
                    padding: isMobile ? "24px 20px" : "24px",
                    gridTemplateColumns: isMobile ? "" : "1fr 1fr",
                    gridAutoFlow: "row",
                    zIndex: 1,

                    // alignItems: "flex-start",
                    // alignContent: "flex-start",
                    // justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: isMobile ? "20px" : "24px",
                    alignSelf: "stretch",
                    borderRadius: { sm: "48px", xs: "24px 24px 0 0" },

                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "none",
                    },
                    minHeight: { xs: "100%", sm: "456px" },
                    alignContent: "flex-start",
                    margin: "auto",
                }}
            >
                {homeDtoV2?.healthCare?.map((item) => (
                    <NewAddon
                        from="benefits"
                        config={{
                            partner: item.partner,
                            title: item.headingText,
                            subtitle: item.subHeadingText,
                            brandImage: item.imageUrl,
                        }}
                        onClick={() =>
                            navigate(`/benefits/${item.customerAppTemplateId}`)
                        }
                    />
                ))}
            </Box>
        </Box>
    );
};

export default BenefitsPage;
