import {
    Box,
    Chip,
    CircularProgress,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../components/iconify";
import NewCardv2 from "../Home/NewCardv2";
import HomeCarousel from "../Home/HomeCarousel";
import { useEffect, useState } from "react";
import ClaimContactCard from "./ClaimContactCard";
import EachPolicyDetails from "./EachPolicyDetails";
import NewNetworkHospitals from "./NewNetworkHospitals";
import Coverages from "./Coverages";
import { policyService } from "../../services/api/policyServics";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
    PolicyState,
    setPolicyDetailDto,
} from "../../features/policy/policySlice";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { CustomerCareDto } from "../../types/DTO/claimsDTO";
import { claimsService } from "../../services/api/claimService";
import { setCustomerCareData } from "../../features/claims/claimSlice";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const NewEachPolicy = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const location = useLocation();
    const policyDetailDto = useSelector(
        (state: RootState) => state.policy.policyDetailDto
    );

    const user = JSON.parse(sessionStorage.getItem("user") || "{}");
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const initialChip = queryParams.get("chip") || "details";
    const from = queryParams.get("from");

    const [selectedChip, setSelectedChip] = useState(initialChip);
    const [claimContactOpen, setClaimContactOpen] = useState(false);
    const dispatch = useDispatch();

    const chipLabels = [
        { label: "Details", value: "details" },

        ...(policyDetailDto?.networkHospital
            ? [{ label: "Network hospital", value: "networkHospital" }]
            : []),
        ...(policyDetailDto?.coverage
            ? [{ label: "Coverages", value: "coverages" }]
            : []),
        ...(policyDetailDto?.networkGarage
            ? [{ label: "Network garage", value: "networkGarage" }]
            : []),
    ];

    const { uuid, pid } = useParams();

    const theme = useTheme();

    const { isLoading, isSuccess, data } = useQuery<
        PolicyState["policyDetailDto"]
    >(
        ["policyDetails", uuid, pid],
        () => policyService.getPolicyDetails(pid as string, uuid as string),
        {
            enabled: !!uuid && !!pid && user?.userType === "EMPLOYEE",
            staleTime: 1000 * 60 * 5,
        }
    );
    const {
        data: customerCareClaimContact,
        isSuccess: customerCareIsSuccess,
        isLoading: customerCareIsLoading,
    } = useQuery<CustomerCareDto>(
        ["customerCareClaimContact"],
        () => claimsService.employer.getClaimContact(uuid),
        {
            staleTime: 60000,
            enabled: !!user && user.userType === "EMPLOYEE",
        }
    );

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    useEffect(() => {
        if (customerCareIsSuccess) {
            dispatch(setCustomerCareData(customerCareClaimContact));
        }
    }, [customerCareIsSuccess, customerCareClaimContact, dispatch]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setPolicyDetailDto(data));
        }
    }, [isSuccess, data, dispatch]);

    // useEffect(() => {
    //     const newQueryParams = new URLSearchParams(location.search);
    //     newQueryParams.set("chip", selectedChip);
    //     navigate({ search: newQueryParams.toString() });
    // }, [selectedChip, location.search, navigate]);

    // create an use effect which detects query param change and sets chip
    useEffect(() => {
        const newQueryParams = new URLSearchParams(location.search);
        if (newQueryParams.get("chip") === null) {
            newQueryParams.set("chip", "details");
            // replace the current location with the new query params dont push
            navigate({ search: newQueryParams.toString() }, { replace: true });
        }
    }, []);
    useEffect(() => {
        const newQueryParams = new URLSearchParams(location.search);
        const chip = newQueryParams.get("chip");
        if (chip) {
            setSelectedChip(chip);
        }
    }, [location.search]);

    const handleChipClick = (value: string) => {
        if (
            value === "networkHospital" &&
            policyDetailDto?.networkHospital?.label === "external"
        ) {
            window.open(policyDetailDto?.networkHospital?.value, "_blank");
            // window.location.href = policyDetailDto?.networkHospital?.value;
            return;
        } else if (
            value === "networkGarage" &&
            policyDetailDto?.networkGarage?.label === "external"
        ) {
            window.open(policyDetailDto?.networkGarage?.value, "_blank");
            // window.location.href = policyDetailDto?.networkGarage?.value;
            return;
        }

        const newQueryParams = new URLSearchParams(location.search);
        newQueryParams.set("chip", value);
        navigate({ search: newQueryParams.toString() });
        setSelectedChip(value);
    };

    const handleClaimContactOpen = () => {
        setClaimContactOpen(!claimContactOpen);
    };

    const renderChipContent = (value: string) => {
        switch (value) {
            case "details":
                return <EachPolicyDetails />;
            case "networkHospital":
                return <NewNetworkHospitals />;
            case "coverages":
                return <Coverages />;
            default:
                return <EachPolicyDetails />;
        }
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    // height: "450px",

                    paddingTop: { sm: "33px", xs: "" },
                    background: `url(${
                        isMobile ? benifitMobilebg : benifitDesktopbg
                    })`,
                    // height: { xs: "100%", sm: "auto" },
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: { sm: "36px", xs: "0px" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "16px",
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        flexDirection: { xs: "column", sm: "row" },
                        padding: { xs: "24px 16px", sm: "0" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: "10px",
                        }}
                    >
                        {/* <Iconify
                            icon="material-symbols:arrow-left-alt"
                            sx={{
                                // marginTop: "5px",
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                            }}
                            width={"32px"}
                            height={"32px"}
                            onClick={() => {
                                const searchParams = new URLSearchParams(
                                    location.search
                                );
                                let from = searchParams.get("from");
                                if (from === "home") {
                                    navigate("/home");
                                } else {
                                    navigate("/plans");
                                }
                            }}
                        /> */}

                        <KeyboardBackspaceIcon
                            sx={{
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => {
                                const searchParams = new URLSearchParams(
                                    location.search
                                );
                                let from = searchParams.get("from");
                                if (from === "home") {
                                    navigate("/home");
                                } else {
                                    navigate("/plans");
                                }
                            }}
                        />

                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            justifyContent={"space-between"}
                        >
                            <Typography
                                variant="h4"
                                sx={{ color: theme.palette?.text?.dark }}
                            >
                                {from === "home"
                                    ? "Cashless hospital list"
                                    : policyDetailDto?.topInfo?.heading}
                            </Typography>
                        </Stack>
                    </Box>
                    {from !== "home" && chipLabels?.length > 1 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                width: { xs: "100%", sm: "auto" },
                                overflowX: "auto",
                                "&::-webkit-scrollbar": {
                                    display: "none",
                                },
                            }}
                        >
                            {chipLabels.map((chip) => (
                                <Chip
                                    key={chip.value}
                                    label={
                                        <Typography
                                            variant="label"
                                            sx={{
                                                color:
                                                    selectedChip === chip.value
                                                        ? "white"
                                                        : "#0C1230",
                                                "@media (min-width: 768px)": {
                                                    ".chip-hover:hover &": {
                                                        color: theme.palette
                                                            .text.primary,
                                                    },
                                                },
                                            }}
                                        >
                                            {chip.label}
                                        </Typography>
                                    }
                                    onClick={() => handleChipClick(chip.value)}
                                    sx={{
                                        backgroundColor:
                                            selectedChip === chip.value
                                                ? theme.palette.purple.main
                                                : "white",
                                        "@media (min-width: 768px)": {
                                            "&:hover": {
                                                backgroundColor:
                                                    theme.palette.purple
                                                        .lighter,
                                                color: theme.palette.text
                                                    .primary,
                                            },
                                        },
                                        "@media (pointer: coarse)": {
                                            "&:focus": {
                                                backgroundColor:
                                                    theme.palette.purple.main,
                                            },
                                            "&:active": {
                                                backgroundColor:
                                                    theme.palette.purple.main,
                                            },
                                        },
                                    }}
                                    className="chip-hover"
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "48px",
                        width: { xs: "100%", sm: "770px" },
                        justifyContent: "center",
                        alignContent: "flex-start",
                        margin: "auto",
                        // height: { xs: "100%", sm: "" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            padding: {
                                sm:
                                    selectedChip === "coverages"
                                        ? "40px 32px"
                                        : "24px",
                                xs: "24px 20px",
                            },
                            minHeight: { sm: "526px", xs: "100%" },
                            alignItems: "flex-start",
                            // justifyContent: "center",
                            alignContent: "flex-start",
                            gap: "24px 20px",
                            // height: { xs: "100%", sm: "auto" },
                            flexWrap: "wrap",
                            borderRadius: { sm: "48px", xs: "24px 24px 0 0" },
                            border: {
                                sm: "1px solid var(--Components-Outline, #ECEFFF)",
                                xs: "none",
                            },
                            position: "relative",
                            background: "#FFF",
                            ...(selectedChip === "details"
                                ? {
                                      flexDirection: {
                                          xs: "column",
                                          sm: "row",
                                      },
                                  }
                                : {}),

                            boxShadow: {
                                sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                                xs: "",
                            },
                            // animation: `${comeFromRight} 0.5s ease-out forwards`,
                        }}
                    >
                        {renderChipContent(selectedChip)}
                    </Box>
                    {selectedChip === "details" && (
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: {
                                        xs: "column-reverse",
                                        sm: "row",
                                    },
                                    width: "100%",
                                    gap: "36px",
                                }}
                            >
                                {/* <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                >
                                    <Stack
                                        direction="column"
                                        width={"100%"}
                                        spacing={"16px"}
                                    >
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                padding: {
                                                    xs: "0px 16px 0px 16px",
                                                    sm: "0px",
                                                },
                                            }}
                                        >
                                            Enhance your insurance
                                        </Typography> */}

                                {/* <NewCardv2
                                            chipLabel={
                                                homeDTO?.d_super_topup_config ===
                                                1
                                                    ? "From ₹595/yr"
                                                    : "From ₹626/yr"
                                            }
                                            width="100%"
                                            subtitle1="Get a Super topup"
                                            body2="Increase your health insurance coverage upto 28 Lacs"
                                            buttonText="Checkout"
                                            logo="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Native+art/Super+topup.png"
                                            onClick={() => {
                                                if (
                                                    homeDTO?.d_super_topup_config ===
                                                    2
                                                ) {
                                                    window.open(
                                                        "https://www.careinsurance.com/rhicl/wellness-combo/bharat-bhushan/info/001",
                                                        "_blank"
                                                    );
                                                } else {
                                                    let config = {
                                                        ...CARE_LOG_GET,
                                                    };
                                                    config.url += "/1";
                                                    advService(config);
                                                    navigate(
                                                        `/carehealthinsurance${
                                                            homeDTO?.d_super_topup_config ===
                                                            1
                                                                ? "/?purchase=true"
                                                                : ""
                                                        }`
                                                    );
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Box> */}
                                {customerCareClaimContact && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1,
                                            padding: {
                                                xs: "0px 20px 0px 20px",
                                                sm: "0px",
                                            },
                                            cursor: "pointer",
                                        }}
                                        onClick={handleClaimContactOpen}
                                    >
                                        <Stack
                                            sx={{
                                                display: "flex",
                                                padding: "24px 16px",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignSelf: "stretch",
                                                borderRadius: "24px",
                                                border: "1px solid var(--Components-Outline, #ECEFFF)",
                                                background: "#FFF",
                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                Connect with claim person
                                            </Typography>
                                            <Iconify
                                                icon={
                                                    "material-symbols:arrow-right-alt"
                                                }
                                                color={"#0C1230"}
                                            />
                                        </Stack>
                                    </Box>
                                )}
                            </Box>
                            {/* <Box
                                sx={{
                                    padding: {
                                        xs: "0px 20px 0px 20px",
                                        sm: "0px",
                                    },
                                }}
                            >
                                <HomeCarousel />
                            </Box> */}
                        </>
                    )}
                </Box>
            </Box>
            <ClaimContactCard
                open={claimContactOpen}
                handleClaimContactOpen={handleClaimContactOpen}
            />
        </>
    );
};

export default NewEachPolicy;
