import TableConfig, {
    ColumnTypes,
    SortingType,
} from "../../types/Components/Table/TableConfig";

const DownloadCenterConfig: TableConfig = {
    id: "downo",
    sorting: {
        type: SortingType.AlphaNumeric,
    },
    search: {
        keys: ["fileName"],
        run: (query, column) => {
            console.log(query, column);
        },
    },
    searchEmptyCall: {
        run: () => {},
    },
    expandable: {
        status: false,
        dataIndex: "DownloadCenter",
    },
    checkBox: false,
    columns: [
        {
            title: "Actions",
            required: false,
            type: ColumnTypes.Action,
            width: "100px",
            numberOfButtons: 4,

            actions: [
                {
                    func: "downloadFile",
                    display: {
                        icon: "download",
                        text: "",
                        condition: "#isExternalUrl == false",
                    },
                },
                {
                    func: "downloadFile",
                    display: {
                        icon: "externalLink",
                        text: "",
                        condition: "#isExternalUrl == true",
                    },
                },
                {
                    func: "editFile",
                    display: {
                        icon: "edit",
                        text: "",
                        condition:
                            "JSON.parse(sessionStorage.getItem('user') || '{}')?.userType !== 'EMPLOYEE'",
                    },
                },
                {
                    func: "deleteFile",
                    display: {
                        icon: "delete",
                        text: "",
                        condition:
                            "JSON.parse(sessionStorage.getItem('user') || '{}')?.userType !== 'EMPLOYEE'",
                    },
                },
            ],
        },
        {
            title: "Name",
            dataIndex: "fileName",
            search: false,
            required: true,
        },
    ],
};

export default DownloadCenterConfig;
