import { BASE_URL, NODE_BE_BASE_URL } from "./config";
// let NODE_BE_BASE_URL = "https://app.insuremyteam.com/api/node/";
// let NODE_BE_BASE_URL = "http://localhost:5000/"

/*
export const EXAMPLE_METHOD = {
  "name": "EXAMPLE",                         //  Name

  "url": BASE_URL + "v1/policy/activate/",   //  url to which call is made

  "method": "METHOD",                        //
                                             //  Possible values -> GET,POST,DELETE,PUT
  
  "success": "toast",                        //  where to show the message if success , 
                                             //    Possible values: 
                                             //       toast -> show a toaster , 
                                             //       popup -> if the modal is open then this will show success message on the popup,
                                             //       ""(empty string) -> don't show any message
  
  "failure": "toast",                        //  where to show the message if error occurs , 
                                             //    Possible values: 
                                             //       toast -> show a toaster ,
                                             //       popup -> if the modal is open then this will show error message on the popup,
                                             //       ""(empty string) -> don't show any message
  
  "processing": "toast",                     //  where to show the message if waiting for request to complete , 
                                             //  Possible values: 
                                             //       toast -> show a toaster , 
                                             //       popup -> if the modal is open then the modal will be blurred and a loading bar will be shown at the top of popup
                                             //       ""(empty string) -> don't show any message
  
  "messageProcessing": "Activating Policy",  //  message to show when waiting for request to complete. (message will be shown on either the popup or on the toast)
                                             //  if #res.somevalue is used in message then the the value is extracted from response and is added in place of #res.somevalue in message
                                             //  if #req.somevalue is used in message then the the value is extracted from payload sent and is added in place of #req.somevalue in message

  "messageSuccess": "Policy Activated",      //  message to show when response is success. 
                                             //  if #res.somevalue is used in message then the the value is extracted from response and is added in place of #res.somevalue in message
                                             //  if #req.somevalue is used in message then the the value is extracted from payload sent and is added in place of #req.somevalue in message

  "messageFail": "#res.data.message",        //  message to show when response is error.
                                             //  if #res.somevalue is used in message then the the value is extracted from response and is added in place of #res.somevalue in message
                                             //  if #req.somevalue is used in message then the the value is extracted from payload sent and is added in place of #req.somevalue in message

  "notes": ""
};
*/

export const ADD_MEMBER_POST = {
    name: "ADD_MEMBER",
    url: BASE_URL + "v1/member",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Adding Member",
    messageSuccess: "Member Successfully added",
    messageFail: "Error in adding member",
    setLoader: () => {},
    notes: "",
};
export const ACTIVATE_POLICY_GET = {
    name: "ACTIVATE_POLICY",
    url: BASE_URL + "v1/policy/activate/v2/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
//export const GET_MASTER_POLICY_GET = {
//    name: "GET_MASTER_POLICY",
//    url: NODE_BE_BASE_URL + "admin/master/plans/unique",
//    method: "GET",
//    success: "",
//    failure: "",
//    processing: "",
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};

//export const GET_MASTER_POLICY_DATA_GET = {
//    name: "GET_MASTER_POLICY_DATA",
//    url: NODE_BE_BASE_URL + "admin/master/plan/unique/",
//    method: "GET",
//    success: "",
//    failure: "",
//    processing: "",
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};
export const ACTIVATE_POLICY_POST = {
    name: "ACTIVATE_POLICY",
    url: BASE_URL + "v1/policy/activate/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    //Dynamic entry which is getting activated
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_INSURERS_GET = {
    name: "GET_INSURERS",
    url: BASE_URL + "v1/master/insurers",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_TPAS_GET = {
    name: "GET_TPAS",
    url: BASE_URL + "v1/master/tpas",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
//export const UPDATE_MASTER_POLICY_PUT = {
//    name: "UPDATE_MASTER_POLICY",
//    url: NODE_BE_BASE_URL + "admin/master/plan/",
//    method: "PUT_MULTIPART",
//    success: "popup",
//    failure: "popup",
//    processing: "popup",
//    //Updating which master plan
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "#res.data.message",
//    setLoader: () => {},
//    notes: "",
//};
//export const ADD_MASTER_POLICY_POST = {
//    name: "ADD_MASTER_POLICY",
//    url: NODE_BE_BASE_URL + "admin/master/plan",
//    method: "POST",
//    success: "popup",
//    failure: "popup",
//    processing: "popup",
//    //Adding Master Policy
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};
export const CREATE_ADMIN_USER_POST = {
    name: "CREATE_ADMIN_USER",
    url: BASE_URL + "v1/user/admin/create/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // Creating which user
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const EDIT_ADMIN_USER_POST = {
    name: "EDIT_ADMIN_USER",
    url: BASE_URL + "v1/user/admin/edit/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // WHich user is being updated
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const DELETE_ADMIN_USER_DELETE = {
    name: "DELETE_ADMIN_USER",
    url: BASE_URL + "v1/user/admin/",
    method: "DELETE",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // Which user is being deleted
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
/*export const GET_ADMIN_USER_LIST_ = {
  "name": "GET_ADMIN_USER_LIST",
  "url": BASE_URL + "",
  "method": "",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};*/

export const GET_BUTTON_ACTION = {
    name: "GET_BUTTON_ACTION",
    url: BASE_URL + "v1/employee/home/v2/path/{templateId}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
};

export const GET_HEALTHCARE_INFO = {
    name: "GET_HEALTHCARE_INFO",
    url: BASE_URL + "v1/employee/benefit/detail/{templateId}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
};

export const GET_POLICY_DETAILS = {
    name: "GET_POLICY_DETAILS",
    url: BASE_URL + "v1/employee/policy/v2/{uuid}/{pid}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
};

export const GET_HEALTH_CARD = {
    name: "GET_HEALTH_CARD",
    url: BASE_URL + "v1/member/card/download/v4/{pId}",
    method: "POST",
    success: "toast",
    failure: "",
    processing: "toast",
    messageProcessing: "Downloading",
    messageSuccess: "Downloaded",
    messageFail: "#res.data.message",
};

export const POST_COUPON_REVEAL = {
    name: "POST_COUPON_REVEAL",
    url: BASE_URL + "v1/employee/benefit/coupon/{templateId}",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
};

export const REEDEEM_COUPON = {
    name: "REEDEEM_COUPON",
    url: BASE_URL + "v1/employee/benefit/redirect/{templateId}",
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
};

export const GTI_GET = {
    name: "GTI",
    url: BASE_URL + "v1/member/policy/check/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

// We need to handle only error for this call
export const GET_MEMBERS_GET = {
    name: "GET_MEMBERS",
    url: BASE_URL + "v1/home/members/v2",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
/*export const PAYMENT_POST = {
  "name": "PAYMENT",
  "url": BASE_URL + "v1/payment/",
  "method": "POST",
  "success": "popup",
  "failure": "popup",
  "processing": "popup",
  "messageProcessing": "Adding Payment",
  "messageSuccess": "#res.data.response",
  "messageFail": "#res.data.message",
  "notes": ""
};
export const CHANGE_PAYMENT_AMOUNT_PUT = {
  "name": "CHANGE_PAYMENT_AMOUNT",
  "url": BASE_URL + "v1/payment/admin",
  "method": "PUT",
  "success": "toast",
  "failure": "toast",
  "processing": "toast",
  "messageProcessing": "Changing Payment",
  "messageSuccess": "#res.data.response",
  "messageFail": "#res.data.message",
  "notes": ""
};
export const PAYMENT_DELETE = {
  "name": "PAYMENT",
  "url": BASE_URL + "v1/payment/",
  "method": "DELETE",
  "success": "toast",
  "failure": "toast",
  "processing": "toast",
  "messageProcessing": "Deleting Payment"
  "messageSuccess": "Successfully deleted the payment",
  "messageFail": "Some problem occured",
  "notes": ""
};
export const PAYMENT_GET = {
  "name": "PAYMENT",
  "url": BASE_URL + "v1/payment/",
  "method": "GET",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const BATCH_GET = {
  "name": "BATCH",
  "url": BASE_URL + "v1/admin/batch/",
  "method": "GET",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const EDIT_ENDORSEMENT_POST = {
  "name": "EDIT_ENDORSEMENT",
  "url": BASE_URL + "v1/admin/batch/update/",
  "method": "POST",
  "success": "toast",
  "failure": "toast",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "Successfully edited the payment",
  "messageFail": "#res.data?.message",
  "notes": ""
};
export const ADMIN_ACTIONS_ = {
  "name": "ADMIN_ACTIONS",
  "url": BASE_URL + "v1/admin/tasks/",
  "method": "",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const AGENT_LOGO_UPLOAD_ = {
  "name": "AGENT_LOGO_UPLOAD",
  "url": BASE_URL + "v1/admin/agent/upload",
  "method": "",
  "success": "toast",
  "failure": "toast",
  "processing": "toast",
  "messageProcessing": "Uploading",
  "messageSuccess": "Image uploaded successfully!",
  "messageFail": "There was an error in uploading the image!",
  "notes": ""
};
export const CLAIM_DOWNLOAD_ = {
  "name": "CLAIM_DOWNLOAD",
  "url": BASE_URL + "v1/download/generic",
  "method": "",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};*/

export const AGENT_GET = {
    name: "AGENT",
    url: BASE_URL + "v1/admin/agent",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const BROKERS_GET = {
    name: "BROKERS",
    url: BASE_URL + "v1/admin/broker",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const AGENTS_LIST_GET = {
    name: "AGENTS_LIST",
    url: BASE_URL + "v1/admin/agents",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const AGENT_POST = {
    name: "AGENT",
    url: BASE_URL + "v1/admin/agent",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // Which agent is getting added
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const AGENT_PUT = {
    name: "AGENT",
    url: BASE_URL + "v1/admin/agent",
    method: "PUT",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // Which agent is getting updated
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const AGENTUSERS_GET = {
    name: "AGENTUSERS",
    url: BASE_URL + "v1/agent",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const AGENTUSERS_DELETE = {
    name: "AGENTUSERS",
    url: BASE_URL + "v1/agent/user",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleted user",
    messageSuccess: "#res.data.message",
    messageFail: "Deleting ",
    setLoader: () => {},
    notes: "",
};
export const AGENTUSERS_POST = {
    name: "AGENTUSERS",
    url: BASE_URL + "v1/agent",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // WHich agent user is getting created
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const AGENTUSERS_PUT = {
    name: "AGENTUSERS",
    url: BASE_URL + "v1/agent",
    method: "PUT",
    success: "popup",
    failure: "popup",
    processing: "popup",
    // WHich agent user is getting created
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const EMPLOYEE_POLICIES_POST = {
    name: "EMPLOYEE_POLICIES",
    url: BASE_URL + "v1/policy/employee",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
/*export const CARE_ADRESS_SEARCH_GET = {
  "name": "CARE_ADRESS_SEARCH",
  "url": BASE_URL + "care/adressSearch",
  "method": "GET",
  "success": "toast",
  "failure": "toast",
  "processing": "toast",
  "messageProcessing": "Searching Pincode Please wait ",
  "messageSuccess": "Pincode found",
  "messageFail": "Pincode Not Found",
  "notes": ""
};
export const POLICY_DETAILS_POST = {
  "name": "POLICY_DETAILS",
  "url": BASE_URL + "v1/policy/employee/features/GMC",
  "method": "POST",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const CARE_CREATE_PROPOSAL_POST = {
  "name": "CARE_CREATE_PROPOSAL",
  "url": BASE_URL + "care/createPolicy",
  "method": "POST",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const CARE_GETSTATUS_GET = {
  "name": "CARE_GETSTATUS",
  "url": BASE_URL + "care/status",
  "method": "GET",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const CARE_GETPDF_GET = {
  "name": "CARE_GETPDF",
  "url": BASE_URL + "care/pdf",
  "method": "GET",
  "success": "",
  "failure": "toast",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "Couldn't download PDF ",
  "notes": ""
};*/

export const ADMIN_CLAIM_COMMENT_POST = {
    name: "ADMIN_CLAIM_COMMENT",
    url: BASE_URL + "v1/admin/claim/interaction/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Changing Status",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_CLAIM_ADMISSION_DOCS_GET = {
    name: "GET_CLAIM_ADMISSION_DOCS",
    url: BASE_URL + "v1/claim/admission/document/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ADMIN_CLAIM_FILE_SELECTION_LIST_POST = {
    name: "ADMIN_CLAIM_FILE_SELECTION_LIST",
    url: BASE_URL + "v1/admin/claim/admission/document/list/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Uploading File",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_CLAIM_INTERACTION_GET = {
    name: "GET_CLAIM_INTERACTION",
    url: BASE_URL + "v1/claim/interaction/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_CLAIM_ADMIN_GET = {
    name: "GET_CLAIM_ADMIN",
    url: BASE_URL + "v1/claim",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ADMIM_UPLOAD_INTERACTION_DOCUMENT_POST = {
    name: "ADMIM_UPLOAD_INTERACTION_DOCUMENT",
    url: BASE_URL + "v1/admin/claim/document/interaction/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Submitting Interaction",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_EMPLOYEE_CLAIM_GET = {
    name: "GET_EMPLOYEE_CLAIM",
    url: BASE_URL + "v1/employee/claim",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const CLAIM_INTIMATE_EMPLOYER_POST = {
    name: "CLAIM_INTIMATE_EMPLOYER",
    url: BASE_URL + "v1/employer/claim",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const CLAIM_INTIMATE_EMPLOYEE_POST = {
    name: "CLAIM_INTIMATE_EMPLOYEE",
    url: BASE_URL + "v1/employee/claim",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const UPLOAD_INTIMATE_DOCUMENT_POST = {
    name: "UPLOAD_INTIMATE_DOCUMENT",
    url: BASE_URL + "v1/claim/admission/document/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Uploading claim document",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const UPLOAD_INTERACTION_DOCUMENT_POST = {
    name: "UPLOAD_INTERACTION_DOCUMENT",
    url: BASE_URL + "v1/employee/claim/document/interaction/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Adding information",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CLAIM_COMMENT_POST = {
    name: "CLAIM_COMMENT",
    url: BASE_URL + "v1/employee/claim/interaction/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Adding information",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_CLAIM_INTIMATE_GET = {
    name: "GET_CLAIM_INTIMATE",
    url: BASE_URL + "v1/claim/admission/document/list/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CLAIM_CUSTOMERCARE_GET = {
    name: "CLAIM_CUSTOMERCARE",
    url: BASE_URL + "v1/claim/contact/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const LOGOUT = {
    name: "LOGOUT",
    url: BASE_URL + "v1/user/logout",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const CLAIM_DETAILS_GET = {
    name: "CLAIM_DETAILS",
    url: NODE_BE_BASE_URL + "company/claim/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CLAIMS_TABLE_GET = {
    name: "CLAIMS_TABLE",
    url: BASE_URL + "v1/claims/v2/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const NEW_CLAIMS_TABLE_GET = {
    name: "NEW_CLAIMS_TABLE",
    url: BASE_URL + "v1/claims/cron/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CLAIM_INTIMATE_ADMIN_PUT = {
    name: "CLAIM_INTIMATE_ADMIN",
    url: BASE_URL + "v1/admin/claim",
    method: "PUT",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_LEAD_INFO_GET = {
    name: "GET_LEAD_INFO",
    url: BASE_URL + "v1/lead/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const REFERENCE_NAME_GET = {
    name: "REFERENCE_NAME",
    url: BASE_URL + "v1/admin/agent/name/map",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const LEAD_COMMENT_GET = {
    name: "LEAD_COMMENT",
    url: BASE_URL + "v1/lead/comments",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const LEAD_COMMENT_POST = {
    name: "LEAD_COMMENT",
    url: BASE_URL + "v1/lead/comment",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const POLICY_UPDATE_POST = {
    name: "POLICY_UPDATE",
    url: BASE_URL + "v1/policy/admin/policy/update/v2/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const EDIT_ESTIMATE_POST = {
    name: "EDIT_ESTIMATE",
    url: BASE_URL + "v1/lead/estimate/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const BANK_POST = {
    name: "BANK",
    url: BASE_URL + "v1/policy/admin/bank/",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const POLICY_LEADS_POST = {
    name: "POLICY_LEADS",
    url: BASE_URL + "v1/lead/extra",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "Updating Lead Data",
    messageSuccess: "Successfully updated lead data!",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const SETTLEMENT_DETAILS_POST = {
    name: "SETTLEMENT_DETAILS",
    url: BASE_URL + "v1/policy/admin/settlement/v2",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "Updating Lead Data",
    messageSuccess: "Successfully updated settelement details!",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const EXPIRE_POLICY_POST = {
    name: "EXPIRE_POLICY",
    url: BASE_URL + "v1/policy/admin/expire/v2/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Expiring Policy",
    messageSuccess: "Policy expired Successfully",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const ENDORSEMENT_DATA_GET = {
    name: "ENDORSEMENT_DATA",
    url: BASE_URL + "v1/policy/endorsement/v2/complete",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ENDORSEMENT_DATA_DELETE = {
    name: "ENDORSEMENT_DATA",
    url: BASE_URL + "v1/policy/endorsement/v2",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ENDORSEMENT_DATA_POST = {
    name: "ENDORSEMENT_DATA",
    url: BASE_URL + "v1/policy/endorsement/v2/complete",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Saving info",
    messageSuccess: "Saved",
    messageFail: "Error saving info",
    setLoader: () => {},
    notes: "",
};
export const ENROLLMENT_GET = {
    name: "ENROLLMENT",
    url: BASE_URL + "v1/enrollment/home",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const DELETE_ENROLLMENT_DELETE = {
    name: "DELETE_ENROLLMENT",
    url: BASE_URL + "v1/enrollment/member/",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting Member",
    messageSuccess: "Member Deleted",
    messageFail: "Error in deleting member",
    setLoader: () => {},
    notes: "",
};
export const CONFIRM_ENROLLMENT_POST = {
    name: "CONFIRM_ENROLLMENT",
    url: BASE_URL + "v1/enrollment/confirm",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Confirming Enrollment",
    messageSuccess: "#res.data.response",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const ENROLLMENT_CHOICE_POST = {
    name: "FEATURE",
    url: BASE_URL + "v1/enrollment/choose",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const FEATURE_GET = {
    name: "FEATURE",
    url: BASE_URL + "v1/policy/admin/features/v2",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const FEATURE_POST = {
    name: "FEATURE",
    url: BASE_URL + "v1/policy/admin/features/v2",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Saving",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const DOCTOR_INTEREST_POST = {
    name: "DOCTOR_INTEREST",
    url: BASE_URL + "v1/plan/dr/",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const HEALTH_DIAGNOSTIC_POST = {
    name: "HEALTH_DIAGNOSTIC",
    url: BASE_URL + "v1/plan/health/",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const HOME_BROKER_AGENT_CHECK_GET = {
    name: "HOME_BROKER_AGENT_CHECK",
    url: BASE_URL + "v1/user/admin/employee/check/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
//export const GET_TOPUP_CHARGES_GET = {
//    name: "GET_TOPUP_CHARGES",
//    url: NODE_BE_BASE_URL + "topup/charges",
//    method: "GET",
//    success: "",
//    failure: "",
//    processing: "",
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};
//export const MARK_INTEREST_IN_TOPUP_POST = {
//    name: "MARK_INTEREST_IN_TOPUP",
//    url: NODE_BE_BASE_URL + "topup/markInterest",
//    method: "POST",
//    success: "",
//    failure: "",
//    processing: "",
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};
export const INSURANCE_GET = {
    name: "INSURANCE",
    url: BASE_URL + "v1/admin/insuranceCompany",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const INSURER_TPAS_GET = {
    name: "INSURER_TPAS",
    url: BASE_URL + "v1/master/insurerTpas",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const INSURANCE_POST = {
    name: "INSURER",
    url: BASE_URL + "v1/admin/insuranceCompany",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const INSURANCE_PUT = {
    name: "INSURER",
    url: BASE_URL + "v1/admin/insuranceCompany",
    method: "PUT",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CLAIM_DETAILS_POST = {
    name: "CLAIM_DETAILS",
    url: NODE_BE_BASE_URL + "company/claim/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Saving claim",
    messageSuccess: "Claim saved",
    messageFail: "Claim couldn't be saved",
    setLoader: () => {},
    notes: "",
};
export const GET_PARAMS_GET = {
    name: "GET_PARAMS",
    url: BASE_URL + "v1/master/params",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_PARAMS_RESPONSE_GET = {
    name: "GET_PARAMS_RESPONSE",
    url: BASE_URL + "v1/admin/params/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const PARAMS_POST = {
    name: "PARAMS",
    url: BASE_URL + "v1/admin/params/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating",
    messageSuccess: "Successfully Updated",
    messageFail: "Error in Updating",
    setLoader: () => {},
    notes: "",
};
export const PARAMS_DELETE = {
    name: "PARAMS",
    url: BASE_URL + "v1/admin/params/",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting",
    messageSuccess: "Successfully Deleted!",
    messageFail: "Error in Deleting",
    setLoader: () => {},
    notes: "",
};
export const GET_BROKER_PARAMS_GET = {
    name: "GET_PARAMS",
    url: BASE_URL + "v1/master/params/agent",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GET_BROKER_PARAMS_RESPONSE_GET = {
    name: "GET_PARAMS_RESPONSE",
    url: BASE_URL + "v1/admin/broker/params/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const BROKER_PARAMS_POST = {
    name: "PARAMS",
    url: BASE_URL + "v1/admin/broker/params/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating",
    messageSuccess: "Successfully Updated",
    messageFail: "Error in Updating",
    setLoader: () => {},
    notes: "",
};
export const BROKER_PARAMS_DELETE = {
    name: "PARAMS",
    url: BASE_URL + "v1/admin/broker/params/",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting",
    messageSuccess: "Successfully Deleted!",
    messageFail: "Error in Deleting",
    setLoader: () => {},
    notes: "",
};
export const GET_LEADS_GET = {
    name: "GET_LEADS",
    url: BASE_URL + "v1/onboard/leads",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
//export const MASTER_POLICY_TABLE_GET = {
//    name: "MASTER_POLICY_TABLE",
//    url: NODE_BE_BASE_URL + "admin/master/plans",
//    method: "GET",
//    success: "",
//    failure: "",
//    processing: "",
//    messageProcessing: "",
//    messageSuccess: "",
//    messageFail: "",
//    setLoader: () => {},
//    notes: "",
//};
//export const UPDATE_MASTER_POLICY_DELETE = {
//    name: "UPDATE_MASTER_POLICY",
//    url: NODE_BE_BASE_URL + "admin/master/plan/",
//    method: "DELETE",
//    success: "toast",
//    failure: "toast",
//    processing: "toast",
//    messageProcessing: "Deleting Master policy",
//    messageSuccess: "Master Policy Successfully deleted",
//    messageFail: "Error in deleting master policy",
//    setLoader: () => {},
//    notes: "",
//};
export const ESTIMATE_PLAN_DELETE = {
    name: "ESTIMATE_PLAN",
    url: BASE_URL + "v1/estimate/plan",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting Plan",
    messageSuccess: "Plan Deleted Successfully",
    messageFail: "Error in deleting deleting plan",
    setLoader: () => {},
    notes: "",
};
export const ESTIMATE_PLAN_GET = {
    name: "ESTIMATE_PLAN",
    url: BASE_URL + "v1/estimate/plan",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ESTIMATE_PLAN_POST = {
    name: "ESTIMATE_PLAN",
    url: BASE_URL + "v1/estimate/plan",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Creating new plan",
    messageSuccess: "New Plan created successfully",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const ESTIMATE_PLAN_PUT = {
    name: "ESTIMATE_PLAN",
    url: BASE_URL + "v1/estimate/plan",
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating plan",
    messageSuccess: "Plan updated successfully",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const EMPLOYEE_EDIT_PUT = {
    name: "EMPLOYEE_EDIT",
    url: BASE_URL + "v1/employee",
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const EMPLOYEE_EDIT_POST = {
    name: "EMPLOYEE_EDIT",
    url: BASE_URL + "v1/employee",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const UNSUBSCRIBE_GET = {
    name: "UNSUBSCRIBE",
    url: BASE_URL + "v1/op/unsubscribe/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const SUBSCRIBE_GET = {
    name: "SUBSCRIBE",
    url: BASE_URL + "v1/op/subscribe/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const VERIFYPHONE_POST = {
    name: "VERIFYPHONE",
    url: BASE_URL + "v1/user/verifyPhone",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MFINE_CHECK_GET = {
    name: "MFINE_CHECK",
    url: BASE_URL + "v1/employee/mfine/doctor",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const RESEND_POST = {
    name: "RESEND",
    url: BASE_URL + "v1/onboard/sendSms",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Sending OTP sms to #req.phone",
    messageSuccess: "Sms sent to #req.phone",
    messageFail: "Some error occured",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_DELETE_DELETE = {
    name: "ESTIMATE_PLAN",
    url: BASE_URL + "v1/member/dependent",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting #req.name",
    messageSuccess: "Successfully Deleted #req.name",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const UPLOAD_CSV_POST = {
    name: "UPLOAD_CSV",
    url: BASE_URL + "v1/member/new/",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "Adding members",
    messageSuccess: "Records Updated",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MEMBERS_PAGE_GET = {
    name: "MEMBERS_PAGE",
    url: BASE_URL + "v1/page/members/v2",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_HOME_GET = {
    name: "MEMBER_HOME",
    url: BASE_URL + "v1/home/members/v2/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_UPDATE_PUT = {
    name: "MEMBER_UPDATE",
    url: BASE_URL + "v1/member/",
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating",
    messageSuccess: "#req.name updated successfully",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_UPDATE_POST = {
    name: "MEMBER_UPDATE",
    url: BASE_URL + "v1/member/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Adding #req.name",
    messageSuccess: "#req.name added successfully",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_UPDATE_DELETE = {
    name: "MEMBER_UPDATE",
    url: BASE_URL + "v1/member/",
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Deleting #req.id",
    messageSuccess: "successfully deleted #req.id",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const TPA_UPDATE_PUT = {
    name: "TPA_UPDATE",
    url: BASE_URL + "v1/member/tpaId/v2/",
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Editing ",
    messageSuccess: "successfully edited",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const CARD_EMAIL_POST = {
    name: "CARD_EMAIL",
    url: BASE_URL + "v1/member/card/email/v2/{pid}",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Sending Email",
    messageSuccess: "Email has been sent",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const FLOAT_BALANCE_UPDATE_POST = {
    name: "FLOAT_BALANCE_UPDATE",
    url: BASE_URL + "v1/policy/admin/floatBalance/v2/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating Float balance",
    messageSuccess: "Float balance updated successfully!",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_RE_ENROLL_POST = {
    name: "MEMBER_RE_ENROLL",
    url: BASE_URL + "v1/member/reenroll",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_ENROLL_EMAIL_POST = {
    name: "MEMBER_ENROLL_EMAIL",
    url: BASE_URL + "v1/member/enrol/mail",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MEMBERS_SEARCH_GET = {
    name: "MEMBERS_SEARCH",
    url: BASE_URL + "v1/member/search/v2",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ADD_MEMBER_PUT = {
    name: "ADD_MEMBER",
    url: BASE_URL + "v1/member",
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating Member",
    messageSuccess: "Successfully updated member",
    messageFail: "Error in updating member",
    setLoader: () => {},
    notes: "",
};
export const FORGOT_PASSWORD_EMAIL_LINK_POST = {
    name: "FORGOT_PASSWORD_EMAIL_LINK",
    url: BASE_URL + "v1/onboard/password/forget",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Sending email",
    messageSuccess: "#res.data.response",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const LOGIN_POST = {
    name: "LOGIN",
    url: BASE_URL + "v1/user/login",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "Logging In",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const LOGIN_POST_OLD = {
    name: "LOGIN",
    url: BASE_URL + "v1/user/login",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "Logging In",
    messageSuccess: "Successfully logged in",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const PASSWORD_VERIFY_LINK_GET = {
    name: "PASSWORD_VERIFY_LINK",
    url: BASE_URL + "v1/onboard/validate/link",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const RESET_PASSWORD_POST = {
    name: "RESET_PASSWORD",
    url: BASE_URL + "v1/onboard/password/reset",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Resetting password",
    messageSuccess: "Password reset successfull! Logging you in ",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const VERIFY_MULTIPLE_PHONE_USERS_POST = {
    name: "VERIFY_MULTIPLE_PHONE_USERS",
    url: BASE_URL + "v1/onboard/reset/sendSms",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const VERIFY_MULTIPLE_PHONE_OTP_POST = {
    name: "VERIFY_MULTIPLE_PHONE_OTP",
    url: BASE_URL + "v1/onboard/reset/verifyOtp",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MAGIC_LOGIN_POST = {
    name: "MAGIC_LOGIN",
    url: BASE_URL + "v1/user/magicLogin",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const SIGN_UP_POST = {
    name: "SIGN_UP",
    url: BASE_URL + "v1/onboard/signup",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const VERIFY_POST = {
    name: "VERIFY_POST",
    url: BASE_URL + "v1/onboard/verify/phone",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Authenticating",
    messageSuccess: "Authentication successfull! Logging you in",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MAGIC_LOGIN_GET = {
    name: "MAGIC_LOGIN",
    url: BASE_URL + "v1/user/magicLogin",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "Authenticating",
    messageSuccess: "Authentication successfull! Logging you in",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const BATCH_POST = {
    name: "BATCH",
    url: BASE_URL + "v1/admin/batch/v2",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "Creating Endorsement",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const DIFF_POST = {
    name: "DIFF",
    url: BASE_URL + "v1/admin/diff",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Removing Trace",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
/*export const AUTHENTICATE_GET = {
  "name": "AUTHENTICATE",
  "url": BASE_URL + "v1/user/authenticate",
  "method": "GET",
  "success": "None",
  "failure": "popup",
  "processing": "popup",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const SIGN_UP_ = {
  "name": "SIGN_UP",
  "url": BASE_URL + "",
  "method": "",
  "success": "",
  "failure": "",
  "processing": "",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "",
  "notes": ""
};
export const CHECK_USER_GET = {
  "name": "CHECK_USER",
  "url": BASE_URL + "v1/user/check",
  "method": "GET",
  "success": "None",
  "failure": "toast",
  "processing": "Loader Form Display",
  "messageProcessing": "",
  "messageSuccess": "",
  "messageFail": "User with email {email} doesn't exist",
  "notes": ""
};*/
export const CARE_LOG_GET = {
    name: "CARE_LOG_GET",
    url: BASE_URL + "v1/employee/careTopUp",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const USER_ID_GET = {
    name: "USER_ID_GET",
    url: BASE_URL + "v1/employee/admin/userId/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const GOOGLE_LOGIN = {
    name: "GOOGLE_LOGIN",
    url: BASE_URL + "v1/authenticate/google",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MICROSOFT_LOGIN = {
    name: "MICROSOFT_LOGIN",
    url: BASE_URL + "v1/authenticate/microsoft",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const CARE_PREVIOUS_VALUE_POST = {
    name: "CARE_PREVIOUS_VALUE",
    url: NODE_BE_BASE_URL + "care/getPolicy",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const MEMBER_DETAILS_POST = {
    name: "MEMBER_DETAILS",
    url: BASE_URL + "v1/member/details",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};
export const ENROLLMENT_NOTINTRESTED_POST = {
    name: "ENROLLMENT_NOTINTRESTED_POST",
    url: BASE_URL + "v1/enrollment/notInterested",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "processing",
    messageSuccess: "#res.data.message",
    messageFail: "Failled",
    setLoader: () => {},
    notes: "",
};

export const ADDONS_REDIRECT_GET = {
    name: "ADDONS_REDIRECT_GET",
    url: BASE_URL + "v1/employee/partner/detail/",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Redirecting",
    messageSuccess: "Redirected",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const ADDONS_POPUP_GET = {
    name: "ADDONS_REDIRECT_GET",
    url: BASE_URL + "v1/employer/partner/",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Redirecting",
    messageSuccess: "Redirected",
    messageFail: "Failed to redirect",
    setLoader: () => {},
    notes: "",
};

export const ADDONS_EMPLOYEE_GET = {
    name: "ADDONS_REDIRECT_GET",
    url: BASE_URL + "v1/employee/partner/",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const WELLNESS_GET = {
    name: "WELLNESS_GET",
    url: BASE_URL + "v1/employee/wellness",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const SURVEY_GET = {
    name: "SURVEY_GET",
    url: BASE_URL + "v1/employee/mentalWellness/survey",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Redirecting",
    messageSuccess: "Redirected",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const ONCO_GET = {
    name: "SURVEY_GET",
    url: BASE_URL + "v1/employee/onco/survey",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Redirecting",
    messageSuccess: "Redirected",
    messageFail: "Server Error",
    setLoader: () => {},
    notes: "",
};

export const OTP_POST = {
    name: "OTP",
    url: BASE_URL + "v1/user/login/otp/send",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "popup",
    messageProcessing: "sending otp",
    messageSuccess: "OTP sent..",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const OTP_POST_WELLNESS = {
    name: "OTP",
    url: BASE_URL + "v1/user/login/otp/wellness/send",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "popup",
    messageProcessing: "sending otp",
    messageSuccess: "OTP sent..",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const OTP_VERIFY_POST = {
    name: "OTP_VERIFY",
    url: BASE_URL + "v1/user/login/otp/verify",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "verifying",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const OTP_VERIFY_POST_WELLNESS = {
    name: "OTP_VERIFY",
    url: BASE_URL + "v1/user/login/otp/wellness/verify",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "verifying",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const SEND_SMS_POST = {
    name: "SEND_SMS",
    url: BASE_URL + "v1/onboard/sendSms",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "sending sms",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const ONBOARD_POST = {
    name: "ONBOARD",
    url: BASE_URL + "v1/user/onboard",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "onboarding",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const MULTIPLE_LOOKUP_POST = {
    name: "MULTIPLE_LOOKUP",
    url: BASE_URL + "v1/user/login/multipleLookup",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "verifying data",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
export const CREATE_POST = {
    name: "CREATE",
    url: BASE_URL + "v1/user/create",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "creating user",
    messageSuccess: "",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const CORPORATE_LINKAGE_OTP_POST = {
    name: "CORPORATE_LINKAGE_OTP_POST",
    url: BASE_URL + "v1/user/link/email",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "Linking user to corporate email",
    messageSuccess: "Linked user to corporate email",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};

export const POLICY_VALIDATION_GET = {
    name: "POLICY_VALIDATION_GET",
    url: BASE_URL + "v1/policy/admin/validation/v2/",
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "Unable to fetch policy validation",
    notes: "",
};

export const POLICY_VALIDATION_POST = {
    name: "POLICY_VALIDATION_POST",
    url: BASE_URL + "v1/policy/admin/validation/v2/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating validations",
    messageSuccess: "Validations Updated",
    messageFail: "Unable to update validations",
    notes: "",
};

export const POLICY_ENROLMENT_GET = {
    name: "POLICY_ENROLMENT_GET",
    url: BASE_URL + "v1/policy/admin/enrolment/v2/",
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "Unable to fetch policy enrolment",
    notes: "",
};

export const POLICY_ENROLMENT_POST = {
    name: "POLICY_ENROLMENT_POST",
    url: BASE_URL + "v1/policy/admin/enrolment/v2/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating enrolment",
    messageSuccess: "Enrolment updated",
    messageFail: "Unable to update enrollment",
    notes: "",
};

export const POLICY_SUMINSURED_GET = {
    name: "POLICY_SUMINSURED_GET",
    url: BASE_URL + "v1/policy/sumInsured/v2/",
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "Unable to fetch polict sumInsured",
    notes: "",
};

export const POLICY_SUMINSURED_POST = {
    name: "POLICY_SUMINSURED_POST",
    url: BASE_URL + "v1/policy/sumInsured/v2/",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Updating SumInsured",
    messageSuccess: "SumInsured Updated",
    messageFail: "Unable to update sumInsured",
    notes: "",
};

export const USER_TYPE_CHANGE = {
    name: "USER_TYPE_CHANGE",
    url: BASE_URL + "v1/user/change/type",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const MULTI_ENROLLMENT_HOME_GET = {
    name: "MULTI_ENROLLMENT_HOME_GET",
    url: BASE_URL + "v1/multi/enrolment/home",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const MULTI_ENROLLMENT_MEMBER_POST = {
    name: "MULTI_ENROLLMENT_MEMBER_GET",
    url: BASE_URL + "v1/multi/enrolment/dependents",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const MULTI_ENROLLMENT_POLICY_POST = {
    name: "MULTI_ENROLLMENT_POLICY_POST",
    url: BASE_URL + "v1/multi/enrolment/policy/",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const MULTI_ENROLLMENT_POLICY_PRICE_POST = {
    name: "MULTI_ENROLLMENT_POLICY_PRICE_POST",
    url: BASE_URL + "v1/multi/enrolment/policy/price/",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const MULTI_ENROLLMENT_CONFIRM_POST = {
    name: "MULTI_ENROLLMENT_CONFIRM_POST",
    url: BASE_URL + "v1/multi/enrolment/confirm",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "Enrolling",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

// create export for /v1/user/login/company/{uuid}/
export const COMPANY_SPECIFIC_LOGIN = {
    name: "COMPANY_SPECIFIC_LOGIN",
    url: BASE_URL + "v1/user/login/company/{uuid}/send",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    notes: "",
    setLoader: () => {},
};

export const COMPANY_SPECIFIC_LOGIN_OTP = {
    name: "COMPANY_SPECIFIC_LOGIN_OTP",
    url: BASE_URL + "v1/user/login/company/{uuid}/verify",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "popup",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    notes: "",
    setLoader: () => {},
};

// create config for v1/onboard/lead
export const LEAD_ONBOARD_POST = {
    name: "LEAD_ONBOARD_POST",
    url: BASE_URL + "v1/onboard/lead",
    method: "POST",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "creating estimate",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

// create config for v1/lead/hard
export const LEAD_HARD_DELETE = {
    name: "LEAD_HARD_DELETE",
    url: BASE_URL + "v1/lead/hard/{uuid}",
    setLoader: () => {},
    method: "DELETE",
    // success: "toast",
    success: "popup",
    failure: "popup",
    processing: "popup",
    messageProcessing: "creating estimate",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const HOSPITAL_LIST_ATTEMPT_GET = {
    name: "HOSPITAL_LIST_ATTEMPT",
    url: BASE_URL + "v1/hospital/admin/{type}/{insurer}/{tpa}",
    setLoader: () => {},
    method: "GET",
    success: "toast",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "fetching data",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};
export const HOSPITAL_LIST_POST = {
    name: "HOSPITAL_LIST_POST",
    url: BASE_URL + "v1/hospital/admin/{type}/{insurer}/{tpa}",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "uploading data",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

// create config for /v1/lead/config/{uuid}
export const LEAD_SPECIFIC_TAB = {
    name: "LEAD_SPECIFIC_TAB",
    url: BASE_URL + "v1/lead/config/{uuid}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "#res.data.message",
    notes: "",
    setLoader: () => {},
};
// create config for MEMBER_PREMIUM popup
export const LEAD_MEMBER_PREMIUM = {
    name: "MEMBER_PREMIUM_GET",
    url: BASE_URL + "v1/payment/update/",
    setLoader: () => {},
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "",
    notes: "",
};

// upload documents configs

export const UPLOAD_DOCUMENT_TIMELINE_GET = {
    name: "UPLOAD_DOCUMENT_TIMELINE_GET",
    url: BASE_URL + "v1/claim/upload/timeline/{claimId}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "",
    notes: "",
};

export const FILE_CLAIM_VIEW_DOC_GET = {
    name: "FILE_CLAIM_VIEW_DOC_GET",
    url: BASE_URL + "v1/claim/view/{claimId}/{documentType}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.response",
    messageFail: "",
    notes: "",
};

export const FILE_CLAIM_UPLOAD_DOC_POST = {
    name: "FILE_CLAIM_UPLOAD_DOC_POST",
    url: BASE_URL + "v1/claim/upload/{claimId}/{documentType}",
    setLoader: () => {},
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const FILE_CLAIM_DELETE_DOC_POST = {
    name: "FILE_CLAIM_DELETE_DOC_POST",
    url: BASE_URL + "v1/claim/view/{claimId}/{claimDocumentId}",
    setLoader: () => {},
    method: "DELETE",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const FILE_CLAIM_SUBMIT_POST = {
    name: "FILE_CLAIM_SUBMIT_POST",
    url: BASE_URL + "v1/claim/upload/complete/{claimId}",
    setLoader: () => {},
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "",
    notes: "",
};

export const WHITELABEL_GET = {
    name: "WHITELABEL_GET",
    url: BASE_URL + "v1/op/url/config/{baseUrl}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "",
    notes: "",
};

export const ALL_CLAIMS_GET = {
    name: "ALL_CLAIMS_GET",
    url: BASE_URL + "v1/claim/employee/timeline",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const UPLOAD_BULK_CLAIMS_POST = {
    name: "UPLOAD_BULK_CLAIMS_POST",
    url: BASE_URL + "v1/admin/upload/claim/v3/{uuid}/{pId}",
    setLoader: () => {},
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const EXCEL_FILE_MAPPING_LIST = {
    name: "EXCEL_FILE_MAPPING_LIST",
    url: BASE_URL + "v1/master/excel/list/Claim/{Agent_uuid}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const Excel_FILE_MAPPING_LIST_MEMBER = {
    name: "Excel_FILE_MAPPING_LIST_MEMBER",
    url: BASE_URL + "v1/master/excel/list/Member/{entityId}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",

    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const GET_EXCEL_FILE_MAPPING = {
    name: "GET_EXCEL_FILE_MAPPING",
    url: BASE_URL + "v1/master/excel/form/{mapping_uuid}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const POST_EXCEL_FILE_MAPPING = {
    name: "POST_EXCEL_FILE_MAPPING",
    url: BASE_URL + "v1/master/excel/form",
    setLoader: () => {},
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const UPDATE_EXCEL_FILE_MAPPING = {
    name: "UPDATE_EXCEL_FILE_MAPPING",
    url: BASE_URL + "v1/master/excel/form",
    setLoader: () => {},
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "#res.data.message",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const UPLOAD_DEF_DOCS_POST = {
    name: "UPLOAD_DEF_DOCS_POST",
    url: BASE_URL + "v1/claim/upload/{claimId}/{documentType}",
    setLoader: () => {},
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const ALL_CLAIM_DOCS_GET = {
    name: "ALL_CLAIM_DOCS_GET",
    url: BASE_URL + "v1/claim/employee/documents/{internalClaimId}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const DELETE_CLAIM_POST = {
    name: "DELETE_CLAIM_POST",
    url: BASE_URL + "v1/claim/employee/delete/{internalClaimId}",
    setLoader: () => {},
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const GET_LETTER_LINK = {
    name: "GET_LETTER_LINK",
    url: BASE_URL + "v1/claim/employee/letter/{internalClaimId}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const POST_SUBMIT_DEFICIENCY = {
    name: "POST_SUBMIT_DEFICIENCY",
    url: BASE_URL + "v1/claim/employee/dataDeficient/{internalClaimId}",
    setLoader: () => {},
    method: "POST",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const GET_TIMELINE_INFO = {
    name: "GET_TIMELINE_INFO",
    url: BASE_URL + "v1/claim/employee/{internalClaimId}/timeline",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const GET_HRA_LINK = {
    name: "GET_HRA_LINK",
    url: BASE_URL + "v1/employee/hra/v1",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const CLAIM_DOWNLOAD_EXCEL_GET = {
    name: "CLAIM_DOWNLOAD_EXCEL_GET",
    url: BASE_URL + "v1/claim/download/excel/{uuid}/{pid}",
    method: "GETFILE",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const KMD_OTP_POST = {
    name: "KMD_OTP_POST",
    url: BASE_URL + "v1/user/login/kmd/architect/reg/verify",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_OTP_VERIFY_POST = {
    name: "KMD_OTP_VERIFY_POST",
    url: BASE_URL + "v1/user/login/kmd/architect/phone/verify",
    method: "POST",
    success: "",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_MEMBERS_GET = {
    name: "KMD_MEMBERS_GET",
    url: BASE_URL + "v1/user/kmd/architect/detail/{masterUuid}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const KMD_PLANS_GET = {
    name: "KMD_PLANS_GET",
    url: BASE_URL + "v1/user/kmd/architect/plan/{key}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const KMD_UPDATE_MEMBERS_POST = {
    name: "KMD_UPDATE_MEMBERS_POST",
    url: BASE_URL + "v1/user/kmd/architect/plan/price/{key}",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_SAVE_MEMBERS_POST = {
    name: "KMD_SAVE_MEMBERS_POST",
    url: BASE_URL + "v1/user/kmd/architect/detail/member/{uuid}",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_KYC_INFO_POST = {
    name: "KMD_KYC_INFO_POST",
    url: NODE_BE_BASE_URL + "kmd/kyc",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Verifying KYC Information",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_KYC_DOCUMENT_POST = {
    name: "KMD_KYC_DOCUMENT_POST",
    url: NODE_BE_BASE_URL + "kmd/kyc/document",
    method: "POST",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_CREATE_PROPOSAL_POST = {
    name: "KMD_CREATE_PROPOSAL_POST",
    url: BASE_URL + "v1/user/kmd/architect/proposal",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Creating Proposal",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_POLICY_DOWNLOAD_GET = {
    name: "KMD_PPOLICY_DOWNLOAD",
    url:
        BASE_URL +
        "v1/user/kmd/architect/policy/download/{policyType}/{policyUuid}",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Downloading ...",
    messageSuccess: "Downloaded",
    messageFail: "#res.data.message",
    notes: "",
};

export const NEW_KMD_POLICY_DOWNLOAD_GET = {
    name: "NEW_KMD_POLICY_DOWNLOAD_GET",
    url: BASE_URL + "v1/policy/employee/download/{policyUuid}/{pid}",
    method: "GET",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Downloading ...",
    messageSuccess: "Downloaded",
    messageFail: "#res.data.message",
    notes: "",
};

export const KMD_PAYMENT_RETRY_POST = {
    name: "KMD_PAYMENT_RETRY_POST",
    url: BASE_URL + "v1/user/kmd/architect/retry/payment/{transactionId}",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "toast",
    messageProcessing: "Retrying Payment",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const AGENT_LEAD_LOGOS_GET = {
    name: "AGENT_LEAD_LOGOS_GET",
    url: BASE_URL + "v1/lead/logos/{uuid}",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const POST_CLAIM_FORM_FILLED = {
    name: "POST_CLAIM_FORM_FILLED",
    url: NODE_BE_BASE_URL + "populateClaimForm",
    setLoader: () => {},
    method: "POST",
    success: "",
    failure: "toast",
    processing: "toast",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const DELETE_ENDORSEMENT = {
    name: "DELETE_ENDORSEMENT",
    url: BASE_URL + "v1/admin/batch/v2/{uuid}/{id}",
    setLoader: () => {},
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const FILE_UPLOAD_DOWNLOAD_CENTER_POST = {
    name: "FILE_UPLOAD_DOWNLOAD_CENTER_POST",
    url: BASE_URL + "v1/employer/document/center/{uuid}",
    setLoader: () => {},
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};
export const DELETE_FILE_DOWNLOAD_CENTER = {
    name: "DELETE_FILE_DOWNLOAD_CENTER",
    url: BASE_URL + "v1/employer/document/center/{uuid}/{fileId}",
    setLoader: () => {},
    method: "DELETE",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const GET_FILE_DOWNLOAD_CENTER = {
    name: "GET_FILE_DOWNLOAD_CENTER",
    url: BASE_URL + "v1/{employerORemployee}/document/center/{uuid}",
    setLoader: () => {},
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    notes: "",
};

export const EDIT_FILE_DOWNLOAD_CENTER = {
    name: "EDIT_FILE_DOWNLOAD_CENTER",
    url: BASE_URL + "v1/employer/document/center/{uuid}",
    setLoader: () => {},
    method: "PUT",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    notes: "",
};

export const REQUEST_LOG_GET = {
    name: "REQUEST_LOG_GET",
    url: BASE_URL + "v1/member/bulk/{uuid}/Lead/Member",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const GOOGLE_LOCATION = {
    name: "GOOGLE_LOCATION",
    url: BASE_URL + "searchPlaces",
    method: "GET",
    success: "",
    failure: "",
    processing: "",
    messageProcessing: "",
    messageSuccess: "",
    messageFail: "",
    setLoader: () => {},
    notes: "",
};

export const PROFILE_EDIT = {
    name: "PROFILE_EDIT",
    url: BASE_URL + "v1/user/profile/edit",
    method: "POST",
    success: "toast",
    failure: "toast",
    processing: "",
    messageProcessing: "",
    messageSuccess: "#res.data.message",
    messageFail: "#res.data.message",
    setLoader: () => {},
    notes: "",
};
