import {
    Box,
    CircularProgress,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify/iconify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import NewPolicyCard from "../Plans/NewPolicyCard";
import { useQuery } from "react-query";
import { policyService } from "../../services/api/policyServics";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
    PolicyState,
    setPolicyCardDto,
} from "../../features/policy/policySlice";

const NewPolicyPage = () => {
    const [selectedChip, setSelectedChip] = useState("Active");

    const { policyCardDto } = useSelector((state: RootState) => state.policy);

    const isMobile = useMediaQuery("(max-width: 768px)");

    const handleChipClick = (label: string) => {
        setSelectedChip(label);
    };

    const theme = useTheme();

    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { data, isSuccess, isLoading } = useQuery<
        PolicyState["policyCardDto"]
    >("policyCards", () => policyService.getPolicyCards(), {
        staleTime: 1000 * 60 * 5,
        enabled: user.userType === "EMPLOYEE",
    });

    useEffect(() => {
        if (isSuccess) {
            dispatch(setPolicyCardDto(data));
        }
    }, [isSuccess, data, dispatch]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        console.log("scroll to top");
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0];
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    // height: "450px",

                    paddingTop: { sm: "33px", xs: "" },
                    background: `url(${
                        isMobile ? benifitMobilebg : benifitDesktopbg
                    })`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    // backgroundPosition: "center",
                    margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: { sm: "36px", xs: "0px" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: { xs: "100%", sm: "770px" },
                        margin: "auto",
                        flexDirection: { xs: "column", sm: "row" },
                        padding: { xs: "24px 16px", sm: "0" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: "10px",
                        }}
                    >
                        {/* <Iconify
                            icon="material-symbols:arrow-left-alt"
                            sx={{
                                // marginTop: "5px",
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                            }}
                            width={"32px"}
                            height={"32px"}
                            onClick={() => navigate("/home")}
                        /> */}
                        <KeyboardBackspaceIcon
                            sx={{
                                color: theme.palette.grey[800],
                                cursor: "pointer",
                                width: "32px",
                                height: "32px",
                            }}
                            onClick={() => navigate("/home")}
                        />
                        <Stack direction="column" gap="4px">
                            <Typography
                                variant="h4"
                                sx={{ color: theme.palette.text.dark }}
                            >
                                My Policies
                            </Typography>
                            <Typography
                                variant="body3"
                                sx={{
                                    width: { xs: "196px", sm: "auto" },
                                    color: theme.palette.text.primary,
                                }}
                            >
                                Find all your policies here.
                            </Typography>
                        </Stack>
                    </Box>
                    {/* <Box
                        sx={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Chip
                            label="Active"
                            onClick={() => handleChipClick("Active")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Active"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Active"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                        <Chip
                            label="Buy Benefits"
                            onClick={() => handleChipClick("Buy Benefits")}
                            sx={{
                                backgroundColor:
                                    selectedChip === "Buy Benefits"
                                        ? "black"
                                        : "white",
                                color:
                                    selectedChip === "Buy Benefits"
                                        ? "white"
                                        : "default",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            }}
                        />
                    </Box> */}
                </Box>
                {isMobile && (
                    <img
                        src="https://imtbepublic.s3.ap-south-1.amazonaws.com/Portal+assets/Miscellaneous+/Policies+BG+illustration.png"
                        alt="bgLogo"
                        width="126px"
                        height="137px"
                        style={{
                            position: "absolute",
                            bottom: isMobile ? "" : "198px",
                            top: isMobile ? "67px" : "",
                            right: 0,
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: "flex",
                        width: { xs: "100%", sm: "770px" },
                        // position: "absolute",
                        // top: { xs: "165px", sm: "135px" },
                        // left: "50%",
                        // transform: "translateX(-50%)",
                        padding: isMobile ? "24px 20px" : "24px",
                        // alignItems: "flex-start",
                        // alignContent: "flex-start",
                        // justifyContent: "space-between",
                        gap: isMobile ? "20px" : "22px",
                        alignSelf: "stretch",
                        flexWrap: "wrap",
                        flexDirection: { xs: "column", sm: "row" },
                        borderRadius: { sm: "48px", xs: "24px 24px 0 0" },

                        border: "1px solid var(--Components-Outline, #ECEFFF)",
                        background: "#FFF",
                        boxShadow: {
                            sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                            xs: "",
                        },
                        minHeight: { xs: "100%", sm: "456px" },
                        margin: "auto",
                        zIndex: 1,
                    }}
                >
                    {policyCardDto?.policyCards?.map(
                        (item: any, index: number) => (
                            <NewPolicyCard
                                key={index}
                                bgImage={item.bgImage}
                                categoryLogo={item.categoryLogo}
                                covers={item?.covers}
                                insuranceCategory={item?.insuranceCategory}
                                heading={item.heading}
                                endDate={item?.endDate}
                                dateAlter={item?.dateAlter}
                                onClick={() =>
                                    navigate(`/plans/${item.uuid}/${item.pId}`)
                                }
                            />
                        )
                    )}
                </Box>
            </Box>
        </>
    );
};

export default NewPolicyPage;
