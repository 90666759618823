import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import benifitDesktopbg from "../../assets/images/addons/Dekstop.svg";
import benifitMobilebg from "../../assets/images/addons/Mobile.svg";
import Iconify from "../../components/iconify";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useEffect } from "react";

const ClaimsContainer = ({
    children,
    className,
    title,
    back,
}: {
    children: React.ReactNode;
    className?: string;
    title?: string;
    back?: () => void;
}) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate();

    useEffect(() => {
        // Try scrolling the window
        window.scrollTo({ top: 0, left: 0 });

        // If you have a custom scroll container, target it like this:
        const mainContent =
            document.getElementsByClassName("body-container_v2")[0]; // Replace 'scroll-container' with your element ID
        if (mainContent) {
            mainContent.scrollTo({ top: 0, left: 0 });
        }
    }, []);

    return (
        <Box
            className={className}
            sx={{
                paddingTop: { sm: "33px", xs: "" },
                background: `url(${
                    isMobile ? benifitMobilebg : benifitDesktopbg
                })`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                margin: { xs: "0px -20px", sm: "0px 0px 0px 0px" },
                display: "flex",
                flexDirection: "column",
                gap: { sm: "36px", xs: "0px" },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "770px" },
                    margin: "auto",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: { xs: "24px 16px", sm: "0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "10px",
                    }}
                >
                    <KeyboardBackspaceIcon
                        sx={{
                            color: (theme) => theme.palette.grey[800],
                            cursor: "pointer",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={back ? back : () => navigate(-1)}
                    />

                    <Stack direction="column" gap="4px">
                        <Typography variant="h4" sx={{ color: "black" }}>
                            {title}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Box
                className="healthCheckup_body"
                sx={{
                    display: "flex",
                    width: { xs: "100%", sm: "770px" },
                    // width: "100%",
                    padding: isMobile ? "24px 20px" : "24px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: isMobile ? "20px" : "22px",
                    alignSelf: "stretch",
                    flexWrap: "wrap",
                    borderRadius: { sm: "48px", xs: "24px 24px 0 0" },

                    border: "1px solid var(--Components-Outline, #ECEFFF)",
                    background: "#FFF",
                    boxShadow: {
                        sm: "0px 2px 12px 0px rgba(39, 57, 147, 0.08)",
                        xs: "none",
                    },
                    minHeight: { xs: "100%", sm: "456px" },
                    alignContent: "flex-start",
                    margin: "auto",
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ClaimsContainer;
